define("comunidades-nafin/initializers/look-and-feel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    Ember.$.ajax({
      url: constant.APIURL + 'usuario/datospersonales/',
      type: "post",
      async: false,
      data: {},
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      xhrFields: {
        withCredentials: true
      },
      dataType: "json",
      success: function success(response) {
        if (response.success == true) {
          window.instanciaConfiguracion = response.configuracion;
          window.usuarioConfiguracion = response.usuario_configuracion;
        }
      },
      error: function error(e) {
        console.log(e);
      }
    });
    Ember.$.ajax({
      url: constant.APIURL + 'usuario/configuraciondeempresa',
      type: "post",
      async: false,
      data: {},
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      xhrFields: {
        withCredentials: true
      },
      dataType: "json",
      success: function success(response) {
        if (response.success == true) {
          if (response.data.look_and_feel) {
            constant.THEME = response.data.look_and_feel;
          }
        }
      },
      error: function error(e) {
        console.log(e);
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});