define("comunidades-nafin/components/nom-035-stps/reportes/cards/entorno-organizacional-card", ["exports", "comunidades-nafin/components/human-talent-components/h-t-card"], function (_exports, _hTCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTCard.default.extend({
    data: {
      total: 0,
      data: {
        aplicados: 0,
        restantes: 0,
        nivel_riesgo: "Sin asignar"
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.set('data', this.data);
    },
    didInsertElement: function didInsertElement() {},
    actions: {}
  });

  _exports.default = _default;
});