define("comunidades-nafin/templates/components/comunidades-movil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bZQPf2St",
    "block": "{\"symbols\":[\"comunidad\"],\"statements\":[[6,\"br\"],[8],[9],[6,\"br\"],[8],[9],[0,\"\\n\"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"comunidades-cards\"]]],null,{\"statements\":[[0,\"\\t\"],[6,\"div\"],[10,\"class\",\"col-6 col-sm-4 col-md-3\"],[10,\"align\",\"center\"],[8],[0,\"\\n\\t\\t\\n\\t\\t\"],[6,\"span\"],[11,\"data-href\",[27,[\"/#/cursos/\",[21,1,[\"comunidad_k\"]]]]],[3,\"action\",[[21,0,[]],\"cursos\",[21,1,[]]]],[8],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[10,\"class\",\"card contenedor-comunidad\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[6,\"img\"],[10,\"class\",\"card-img comunidad-imagen\"],[11,\"src\",[27,[[21,1,[\"img\"]]]]],[10,\"alt\",\"\"],[8],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n            \\n\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[6,\"div\"],[10,\"class\",\"font-responsive py-2 px-1\"],[8],[1,[21,1,[\"nombre\"]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"],[6,\"div\"],[10,\"class\",\"row margin_tb_10px\"],[8],[0,\"\\n\\t\\n\\t\"],[6,\"div\"],[10,\"class\",\"col-12 \"],[8],[0,\"\\n\\t\\t\"],[6,\"button\"],[10,\"class\",\"btn btn-theme  btn-lg btn-block\"],[10,\"id\",\"seeMoreCommunities\"],[10,\"type\",\"button\"],[8],[6,\"i\"],[10,\"class\",\"fa fa-eye\"],[8],[9],[0,\" Mostrar más comunidades\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\\n\"],[9],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/comunidades-movil.hbs"
    }
  });

  _exports.default = _default;
});