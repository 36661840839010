define("comunidades-nafin/components/sub-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    lastScrollTop: 0,
    didScroll: false,
    delta: 5,
    navbarHeight: 0,
    hasScrolled: function hasScrolled() {
      this._super();

      var st = $(document).scrollTop(); // Make sure they scroll more than delta

      if (Math.abs(this.lastScrollTop - st) <= this.delta) return; // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.

      if (st > this.lastScrollTop && st > this.navbarHeight) {
        // Scroll Down
        $('footer').removeClass('nav-down').addClass('nav-up');
      } else {
        // Scroll Up
        if (st + $(window).height() < $(document).height()) {
          $('footer').removeClass('nav-up').addClass('nav-down');
        }
      }

      this.lastScrollTop = st;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      this.navbarHeight = $('footer').outerHeight();
      $(window).scroll(function (event) {
        self.didScroll = true;
      });
      setInterval(function () {
        if (self.didScroll) {
          self.hasScrolled();
          self.didScroll = false;
        }
      }, 250);

      var self = this._super.apply(this, arguments); //Redes sociales


      self.set('general_navegacion_link_pagina_facebook', false);
      if (window.instanciaConfiguracion != undefined && window.instanciaConfiguracion.general_navegacion_link_pagina_facebook != false) self.set('general_navegacion_link_pagina_facebook', window.instanciaConfiguracion.general_navegacion_link_pagina_facebook);
      self.set('general_navegacion_link_pagina_twitter', false);
      if (window.instanciaConfiguracion != undefined && window.instanciaConfiguracion.general_navegacion_link_pagina_twitter != false) self.set('general_navegacion_link_pagina_twitter', window.instanciaConfiguracion.general_navegacion_link_pagina_twitter);
    }
  });

  _exports.default = _default;
});