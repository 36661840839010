define("comunidades-nafin/components/menu-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    bus: Ember.inject.service('evaluacion-curso'),
    conver_vis: false,
    urlAPP: document.location.href.split("#")[0],
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      var secciones = document.location.href.split("index");

      if (secciones.length == 1) {
        secciones = document.location.href.split("#");
      }

      self.set("habilitarSocket", window.constant.HABILITAR_SOCKET);
      Ember.$('#chat_box_component').hide();
      self.set("rootURL", secciones[0]);
      self.set("logo", "lookandfeel/" + window.constant.THEME + "/img/logo.png");

      if (window.datosPersonales != undefined) {
        var data = window.datosPersonales;

        if (data.foto) {
          if (data.foto.split('/')[0] === 'uploads') {
            data.foto = constant.BASEURL + data.foto;
          }

          self.set('avatar', data.foto);
          self.loadConversations();
          Ember.$('#notificacionChat').hide();
        }

        window.document.title = window.instanciaConfiguracion.nombre_completo;
        if (window.permisos_navegacion.general_navegacion_redirect_home != false) self.set("redirect_home", true);
        if (window.permisos_navegacion.general_navegacion_comunidades_visible != false) self.set("comun_vis", true);
        if (window.permisos_navegacion.general_navegacion_cursos_visible != false) self.set("cursos_vis", true); //Explora

        self.set("explora_vis", true);

        if (window.permisos_navegacion.general_navegacion_diagnostico_empresarial_visible == false && window.permisos_navegacion.general_navegacion_webinars_visible == false && window.permisos_navegacion.general_navegacion_noticias_visible == false && window.permisos_navegacion.general_navegacion_eventos_visible == false && window.permisos_navegacion.general_navegacion_foros_visible == false && window.permisos_navegacion.general_navegacion_mediateca_visible == false && window.permisos_navegacion.general_navegacion_consultas_visible == false && window.permisos_navegacion.general_navegacion_contactos_visible == false) {
          self.set("explora_vis", false);
        }

        if (window.permisos_navegacion.general_navegacion_diagnostico_empresarial_visible != false) {
          self.set("diag_vis", true);
          Ember.$(".diagnosticos_cards").removeClass("d-none");
        }

        if (window.permisos_navegacion.general_navegacion_webinars_visible != false) {
          self.set("web_vis", true);
        }

        if (window.permisos_navegacion.general_navegacion_noticias_visible != false) {
          self.set("noti_vis", true);
          Ember.$(".noticias_cards").removeClass("d-none");
        }

        if (window.permisos_navegacion.general_navegacion_eventos_visible != false) {
          self.set("even_vis", true);
          Ember.$(".eventos_cards").removeClass("d-none");
        }

        if (window.permisos_navegacion.general_navegacion_foros_visible != false) {
          self.set("foro_vis", true);
          Ember.$(".foros_cards").removeClass("d-none");
        }

        if (window.permisos_navegacion.general_navegacion_mediateca_visible != false) {
          self.set("media_vis", true);
          Ember.$(".mediateca_cards").removeClass("d-none");
        }

        if (window.permisos_navegacion.general_navegacion_consultas_visible != false) self.set("consul_vis", true);
        if (window.permisos_navegacion.general_navegacion_contactos_visible != false) self.set("dico_vis", true); //Búsqueda, Preguntas, Logros, Mensajes y Notificaciones.

        if (window.permisos_navegacion.general_navegacion_busqueda_visible != false) self.set("bus_vis", true);
        if (window.permisos_navegacion.general_navegacion_preguntas_frecuentes_visible != false) self.set("preg_vis", true);
        if (window.permisos_navegacion.general_navegacion_logros_reconocimientos_visible != false) self.set("award_vis", true);
        if (window.permisos_navegacion.general_navegacion_conversaciones_visible != false) self.set("conver_vis", true);

        if (window.permisos_navegacion.general_navegacion_notificaciones_visible != false) {
          self.set("notifica_vis", true);
        } else {
          Ember.$("#notifica_vis").show();
        } //Perfil


        self.set("avatar_vis", true);

        if (window.permisos_navegacion.general_navegacion_mi_perfil_nafin_visible == false && window.permisos_navegacion.general_navegacion_cambiar_password_visible == false && window.permisos_navegacion.general_navegacion_aviso_privacidad_visible == false && window.permisos_navegacion.general_navegacion_finalizar_sesion_visible == false) {
          self.set("avatar_vis", false);
        }

        if (window.permisos_navegacion.general_navegacion_mi_perfil_nafin_visible != false) self.set("perfil_vis", true);
        if (window.permisos_navegacion.general_navegacion_cambiar_password_visible != false) self.set("contra_vis", true);
        if (window.permisos_navegacion.general_navegacion_aviso_privacidad_visible != false) self.set("aviso_vis", true);
        if (window.permisos_navegacion.general_navegacion_finalizar_sesion_visible != false) self.set("sesion_vis", true);
        if (window.permisos_navegacion.general_navegacion_comunidades_diccionario_visible != false) self.set("diccionario_vis", true);
        self.set("navegacion-administracion-comunidades-comunidades", true);

        if (window.permisos_navegacion.general_navegacion_adm_comunidades_visible == false) {
          self.set("navegacion-administracion-comunidades-comunidades", false);
        } //Logros


        if (window.permisos_navegacion.general_navegacion_adm_logros_visible != false) {
          self.set("navegacion-administracion-logros-logros", true);
        } //Usuarios


        if (window.permisos_navegacion.general_navegacion_adm_usuarios_visible != false) {
          self.set("navegacion-administracion-usuarios-usuarios", true);
        } //Reportes


        if (window.permisos_navegacion.general_navegacion_adm_reportes_visible != false) {
          self.set("navegacion-administracion-reportes-reportes", true);
        }

        if (window.permisos_navegacion.general_navegacion_adm_dashboard_visible != false) {
          self.set("navegacion-administracion-reportes-dashboard", true);
        } //Cursos


        if (window.permisos_navegacion.general_navegacion_adm_cursos_visible != false) {
          self.set("navegacion-administracion-cursos-cursos", true);
        }

        if (window.permisos_navegacion.general_navegacion_adm_encuestas_visible != false) {
          self.set("navegacion-administracion-cursos-encuestas", true);
        }

        if (window.permisos_navegacion.general_navegacion_adm_certificados_visible != false) {
          self.set("navegacion-administracion-cursos-certificados", true);
        }
        /*
         *
         */


        if (window.permisos_navegacion.general_navegacion_nom_035_reportes_admin_visible === true) {
          self.set("navegacion_higienizacion_covid19_reportes_administrador", true);
        }
        /*
         *
         */


        if (window.permisos_navegacion.general_navegacion_covid19_reportes_admin_visible === true) {
          self.set("navegacion_nom035_stps_reportes_administrador", true);
        }

        Ember.$("#selectEscolaridad").show();

        if (window.instanciaConfiguracion.usuarios_filtro_escolaridad == false) {
          Ember.$("#selectEscolaridad").remove();
        }

        Ember.$("#selectTipo").show();

        if (window.instanciaConfiguracion.usuarios_filtro_tipo == false) {
          Ember.$("#selectTipo").remove();
        }

        Ember.$("#selectEstado").show();

        if (window.instanciaConfiguracion.usuarios_filtro_estado == false) {
          Ember.$("#selectEstado").remove();
        }

        Ember.$("#selectMunicipio").show();

        if (window.instanciaConfiguracion.usuarios_filtro_municipio == false) {
          Ember.$("#selectMunicipio").remove();
        }

        Ember.$("#formBusqueda").removeClass("d-none");
        setTimeout(function () {
          self.showOrHide();
        }, 1000);
        Ember.$('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
          if (!Ember.$(this).next().hasClass('show')) {
            Ember.$(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
          }

          var $subMenu = Ember.$(this).next(".dropdown-menu");
          $subMenu.toggleClass('show');
          Ember.$(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function (e) {
            Ember.$('.dropdown-submenu .show').removeClass("show");
          });
          return false;
        });
      }
    },

    /**
     *
     */
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var idioma = {};

      if (window.idioma) {
        idioma = window.idioma;
      }

      this.set("idioma", idioma);
    },
    showOrHide: function showOrHide() {
      Ember.$(".menu-list").each(function () {
        var menuList = Ember.$(this);
        menuList.hide();
        menuList.find("ul:first .submenu-list").each(function () {
          var submenuList = Ember.$(this);
          submenuList.hide();

          if (submenuList.find(" ul:first li").length) {
            submenuList.show();
            menuList.show();
          }
        });
      });
    },
    loadConversations: function loadConversations() {},
    search: function search(_searchValue) {
      if (!_searchValue) {
        return;
      }

      if (_searchValue.trim().length == 0) {
        return;
      }

      document.location.href = '/#/buscador?search=' + _searchValue.trim();
    },
    actions: {
      closeSession: function closeSession() {
        var self = this;
        Ember.$.ajax({
          url: constant.APIURL + 'usuario/logout/',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          success: function success(r) {
            localStorage.removeItem("userlogin");
            localStorage.removeItem("passlogin");
            window.location.href = constant.URL_REDIRECT_AFTER_LOGOUT;
          },
          error: function error(e) {
            console.log(e);
          }
        });
      },
      iniciarSesion: function iniciarSesion() {
        window.location.href = self.urlAPP;
      },
      goInicio: function goInicio() {
        if (window.usuarioConfiguracion) {
          if (window.usuarioConfiguracion.url_redireccion_login) {
            /*
             *  Sí existe definido un home en particular para el usuarios evaluamos que este dentro de este dentro
             *  de este dominio (puede que la URL lo envie al subdomio del backend. El cual en este contexto no es
             *  correcto.
             */
            if (window.usuarioConfiguracion.url_redireccion_login.indexOf(window.location.host) != -1) {
              document.location.href = window.usuarioConfiguracion.url_redireccion_login;
            } else {
              this.get('router').transitionTo('inicio');
            }
          } else {
            this.get('router').transitionTo('inicio');
          }
        } else {
          this.get('router').transitionTo('inicio');
        }
      },
      goCommunities: function goCommunities() {
        this.get('router').transitionTo('comunidades');
      },
      goCourses: function goCourses() {
        this._super.apply(this, arguments);

        localStorage.setItem("comunidad_k", "");
        var currentRoute = this.get('router').get('currentRouteName');

        if (currentRoute != 'cursos') {
          this.get('router').transitionTo('cursos');
        } else {// document.location.href='/#/cursos';
        }
      },
      goDiagnostic: function goDiagnostic() {
        this.get('router').transitionTo('diagnostico-empresarial', 0);
      },
      goNews: function goNews() {
        this.get('router').transitionTo('noticias');
      },
      goEvents: function goEvents() {
        this.get('router').transitionTo('eventos');
      },
      goWebinars: function goWebinars() {
        this.get('router').transitionTo('webinars');
      },
      goForums: function goForums() {
        this.get('router').transitionTo('foros');
      },
      goMediateca: function goMediateca() {
        this.get('router').transitionTo('mediateca');
      },
      goConsultas: function goConsultas() {
        this.get('router').transitionTo('consultas');
      },
      goContacts: function goContacts() {
        this.get('router').transitionTo('directorio');
      },
      goFaqs: function goFaqs() {
        this.get('router').transitionTo('faqs');
      },
      goMyProfile: function goMyProfile() {
        this.get('router').transitionTo('perfil');
      },
      goChangePassword: function goChangePassword() {
        this.get('router').transitionTo('cambiar-contrasenia');
      },
      goAchievments: function goAchievments() {
        this.get('router').transitionTo('logros');
      },
      goDataPolicy: function goDataPolicy() {
        //window.open(constant.APIURL + 'publico/avisoprivacidad/activo', '_blank');
        var options = "";
        var where = "_blank";

        if (device.platform == "Android") {
          options = 'location=yes,hidden=no,closebuttoncaption=Volver,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
          where = "_system";
        } else {
          options = 'location=no,hidden=no,closebuttoncaption=Volver,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
        }

        var pageContentUrl = constant.APIURL + 'publico/avisoprivacidad/activo';
        var ref = cordova.InAppBrowser.open(pageContentUrl, where, options);
        ref.addEventListener('loadstart', function (event) {});
        ref.addEventListener('loadstop', function (event) {});
        ref.addEventListener('exit', function (event) {});
      },
      goCursosOffline: function goCursosOffline() {
        window.location.href = window.location.href.split("#")[0] + "#/comunidades-offline";
        window.location.reload(); //this.get('router').transitionTo('cursos-offline');            
      },
      //ADMIN
      goComunidadesAdmin: function goComunidadesAdmin() {
        this.get('router').transitionTo('administracion-comunidades');
      },
      goLogrosAdmin: function goLogrosAdmin() {
        this.get('router').transitionTo('administracion-logros');
      },
      goEmailAdmin: function goEmailAdmin() {
        alert('En construcción');
      },
      goDictionaryAdmin: function goDictionaryAdmin() {
        this.get('router').transitionTo('administracion-diccionario');
      },
      goPrivacyAdmin: function goPrivacyAdmin() {
        alert('En construcción');
      },
      goTicketsAdmin: function goTicketsAdmin() {
        this.get('router').transitionTo('consultas');
      },
      goPredefAswersAdmin: function goPredefAswersAdmin() {
        this.get('router').transitionTo('respuestas-predefinidas');
      },
      goAswersTypeAdmin: function goAswersTypeAdmin() {
        this.get('router').transitionTo('tipos-de-respuesta');
      },
      goUsersAdmin: function goUsersAdmin() {
        this.get('router').transitionTo('administracion-usuarios');
      },
      goRolesAdmin: function goRolesAdmin() {
        alert('En construcción');
      },
      goReportsAdmin: function goReportsAdmin() {
        this.get('router').transitionTo('reportes');
      },
      goDashboardAdmin: function goDashboardAdmin() {
        this.get('router').transitionTo('dashboard');
      },
      goCoursesAdmin: function goCoursesAdmin() {
        this.get('router').transitionTo('administracion-cursos');
      },
      goLearningCoursesAdmin: function goLearningCoursesAdmin() {
        alert('En construcción');
      },
      goCoursesCatalogAdmin: function goCoursesCatalogAdmin() {
        this.get('router').transitionTo('catalogo-cursos');
      },
      goEvaluationsAdmin: function goEvaluationsAdmin() {
        this.get('router').transitionTo('catalogo-evaluaciones');
      },
      goSurveysAdmin: function goSurveysAdmin() {
        this.get('router').transitionTo('catalogo-encuestas');
      },
      goCertificatesAdmin: function goCertificatesAdmin() {
        this.get('router').transitionTo('catalogo-certificado');
      },
      goQuestionsAdmin: function goQuestionsAdmin() {
        this.get('router').transitionTo('repositorio-preguntas');
      },

      /**
       *
       */
      goReportesnom035Stps: function goReportesnom035Stps() {
        document.location.href = '/#/nom-035-stps/reportes/administrador';
      },

      /**
       *
       */
      goReportesHigienizacionCovid19: function goReportesHigienizacionCovid19() {
        document.location.href = '/#/higienizacion-covid19/reportes/administrador/resultados-generales';
      },

      /**
       *
       */
      search: function search() {
        var self = this;
        Ember.$('.none_border').each(function () {
          self.search(Ember.$(this).val());
        });
      },
      searchEnter: function searchEnter(event) {
        var self = this;

        if (event.which == 13) {
          event.preventDefault();
          Ember.$('.none_border').each(function () {
            self.search(Ember.$(this).val());
          });
        }
      },
      chat: function chat() {
        // $('#notificacionChat').hide();
        Ember.$('#chat_box_component').show();
      },
      descargarAviso: function descargarAviso() {
        Ember.$.ajax({
          url: constant.APIURL + 'publico/avisoprivacidad/urlavisoprivacidad',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          success: function success(url) {
            var fileTransfer = new FileTransfer();
            var uri = encodeURI(url);
            var recurso = "Aviso de privacidad.pdf";
            var pathDownloads = "";
            var self = this;
            var localPath = "temporal";

            if (device.platform == "Android") {
              pathDownloads = cordova.file.dataDirectory + "downloads/" + localPath;
            } else {
              pathDownloads = cordova.file.documentsDirectory + "downloads/" + localPath;
            }

            var fileURL = pathDownloads + "/" + recurso;
            fileTransfer.download(uri, fileURL, function (entry) {
              cordova.plugins.fileOpener2.open(entry.toURL(), // You can also use a Cordova-style file uri: cdvfile://localhost/persistent/Downloads/starwars.pdf
              'application/pdf', {
                error: function error(e) {
                  console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                },
                success: function success() {
                  console.log('file opened successfully');
                }
              });
            }, function (error) {
              console.log("Ha ocurrido un error durante la descompresion, por favor intente mas tarde...");
              console.log("download error source " + error.source);
              console.log("download error target " + error.target);
              console.log("download error code" + error.code);
            });
          },
          error: function error(e) {
            console.log(e);
          }
        }); //
      }
    }
  });

  _exports.default = _default;
});