define("comunidades-nafin/templates/components/nom-035-stps/reportes/cards/entorno-organizacional-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "38KY5Mho",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"card\"],[8],[0,\"\\n\\n    \"],[6,\"div\"],[10,\"class\",\"card-body\"],[8],[0,\"\\n\\n        \"],[6,\"h6\"],[10,\"class\",\"card-subtitle mb-2 text-muted text-center\"],[8],[0,\"\\n            Factores de Riesgo Psicosocial y Entorno Organizacional (Guía III)\\n        \"],[9],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"col-6 \"],[8],[0,\"\\n                \"],[6,\"div\"],[10,\"class\",\"text-center\"],[8],[0,\" \\n                    \"],[6,\"div\"],[10,\"class\",\"total\"],[8],[0,\"\\n                        \"],[6,\"div\"],[8],[1,[22,[\"data\",\"data\",\"aplicados\"]],false],[0,\" / \"],[1,[22,[\"data\",\"total\"]],false],[9],[0,\"\\n                        \"],[6,\"div\"],[10,\"class\",\"text-center total-small\"],[8],[0,\"aplicados\"],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[6,\"div\"],[10,\"class\",\"col-6 \"],[8],[0,\"\\n                \"],[6,\"div\"],[10,\"class\",\"text-center total-small pt-1\"],[8],[0,\"Nivel de riesgo\"],[9],[0,\"\\n                \"],[6,\"div\"],[10,\"class\",\"text-center factor-nivel-riesgo text-success\"],[8],[1,[22,[\"data\",\"data\",\"nivel_riesgo\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[13,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/nom-035-stps/reportes/cards/entorno-organizacional-card.hbs"
    }
  });

  _exports.default = _default;
});