define("comunidades-nafin/components/course-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this._super.apply(this, arguments);

      Ember.$(".text-cursos").addClass("selected-option-menu");
      Ember.$.ajax({
        url: constant.APIURL + '/comunidad/listarMisInscripcionesRa',
        type: 'POST',
        contentType: 'application/x-www-form-urlencoded',
        data: 'params={"agrupar":"agrupar_por_cursos"}',
        xhrFields: {
          withCredentials: true
        },
        success: function success(response) {
          var r = JSON.parse(response); //console.log(r.data);

          Object.keys(r.data).forEach(function (idx) {
            if (r.data[idx].porcentaje_avance) {
              var porcentaje = parseInt(r.data[idx].porcentaje_avance);
              r.data[idx].porcentaje_avance = porcentaje;
            } //r.data[idx].imagen_raprendizaje = constant.BASEURL + r.data[idx].imagen_raprendizaje;


            if (r.data[idx].estatus === "8") {
              r.data[idx].imagen = "/img/cursos/aprobado.png";
            } //r.data[idx].imagen_raprendizaje = constant.BASEURL + r.data[idx].imagen_raprendizaje;


            if (r.data[idx].imagen_raprendizaje === undefined) {
              r.data.pop(r.data[idx]);
            }
          });

          if (constant.FIREBASE_SCORE != '') {
            //Vamos por la calificación de los cursos
            Ember.$.ajax({
              url: constant.FIREBASE_SCORE,
              type: 'POST',
              data: {
                "method": "score_courses_resume"
              },
              success: function success(score_courses_resume) {
                //self.set("allCoursesScores", score_courses_resume.data);
                //Se le agrega la votación desde Firebase
                r.data.forEach(function (item, idx) {
                  r.data[idx].imagen_raprendizaje = solveImageURL(r.data[idx].imagen_raprendizaje);

                  if (score_courses_resume.data) {
                    //respons.data[idx].ranking_raprendizaje = score_courses_resume.data[respons.data[idx].ra_k];
                    if (typeof score_courses_resume.data[r.data[idx].ra_k] != 'undefined' && score_courses_resume.data[r.data[idx].ra_k] != null) {
                      r.data[idx].ranking_raprendizaje_score = Math.round(score_courses_resume.data[r.data[idx].ra_k].rate);
                    } else {
                      r.data[idx].ranking_raprendizaje_score = 0;
                    }
                  } else {
                    r.data[idx].ranking_raprendizaje_score = 0;
                  }
                }); //Se ordennan por ranking
                //r.data = r.data.sort((b,a) => (a.ranking_raprendizaje_score > b.ranking_raprendizaje_score) ? 1 : ((b.ranking_raprendizaje_score > a.ranking_raprendizaje_score) ? -1 : 0));
                //Se ordennan por nombre
                //r.data = r.data.sort((a, b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0));
                //self.set("allCourses", r.data);

                r.data = r.data.sort(function (a, b) {
                  return a.ranking_raprendizaje < b.ranking_raprendizaje ? 1 : b.ranking_raprendizaje < a.ranking_raprendizaje ? -1 : 0;
                }); // let cursosV = [];
                // for (var i = 0; i < 12; i++) {
                // 	if (r.data[self.offset + i]) {
                // 		cursosV.push(r.data[self.offset + i]);
                // 	}
                // }

                self.set('curso', r.data); // self.offset += 12;

                setTimeout(function () {
                  Ember.$('#loading').fadeOut('slow');
                  lazyLoad.update();
                }, 3000);
              }
            });
          } else {
            r.data = r.data.sort(function (a, b) {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            }); //self.set("allCourses", r.data);
            //r.data = r.data.sort((a, b) => { return (a.ranking_raprendizaje < b.ranking_raprendizaje) ? 1 : ((b.ranking_raprendizaje < a.ranking_raprendizaje) ? -1 : 0); });
            // let cursosV = [];
            // for(var i = 0; i < 12; i++){
            // 	if( r.data[self.offset+i] ){
            // 		cursosV.push(r.data[self.offset+i]);
            // 	}
            // }

            self.set('curso', r.data); // self.offset += 12;				

            setTimeout(function () {
              Ember.$('#loading').fadeOut('slow');
              lazyLoad.update();
            }, 3000);
          } //console.log(r.data);
          //self.set('curso',r.data);

        },
        error: function error(e) {
          console.error(e);
        }
      });
    },
    numOfTimes: Ember.computed('times', function () {
      return [1, 2, 3, 4, 5];
    }),
    didRender: function didRender() {
      Ember.$('#caruselActive .carousel-item:first-child').addClass('active');
      Ember.$('.carousel').carousel({
        interval: 5000
      });
    },
    actions: {
      prevSlide: function prevSlide() {
        Ember.$('.carousel-inner.box_shado.courses-cards').carousel('prev');
      },
      nextSlide: function nextSlide() {
        Ember.$('.carousel-inner.box_shado.courses-cards').carousel('next');
      }
    }
  });

  _exports.default = _default;
});