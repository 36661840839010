define("comunidades-nafin/components/course-evaluation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    bus: Ember.inject.service('evaluacion-curso'),
    zip_url: "",
    imageComunidad: "",
    nombreRuta: "",
    temporalBase64: "",
    didInsertElement: function didInsertElement() {
      var self = this; // Almacenamos en un inicio la imagen de comunidad en base64

      var urlComunidad = "";
      var thumbComunidad = "";
      self.set("calificarCurso", true);
      self.set("imageComunidad", "");
      self.set("imageRa", "");
      self.set("botonDescargaGeneral", false); // Verificamos si existe informacion por sincronizar

      self.datosUsuario();
      self.existeTrackinRa();
      self.guardarImagenRuta();
      self.misComunidades();
      self.set("procesoAnterior", true);
      self.LoadData();
    },
    didRender: function didRender() {
      var self = this;
      alert("didRender");
    },
    _willRender: function _willRender() {},
    misComunidades: function misComunidades() {
      var self = this; // Obtenemos las comunidades para el offline

      Ember.$.ajax({
        url: constant.APIURL + 'comunidad/listarMisComunidades',
        type: 'POST',
        async: false,
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        success: function success(r) {
          var response = JSON.parse(r);

          if (response.success) {
            var comunidades_ras_nuevo = []; // Reviso las comunidades a las que tengo acceso

            var comunidades = response.data;

            if (comunidades.length) {
              self.set("misComunidades", comunidades);
              var comunidades_ras = localStorage.getItem("comunidades_ras");

              if (comunidades_ras != null) {
                for (var i = 0; i < comunidades.length; i++) {
                  var array_comunidas_ras = JSON.parse(comunidades_ras);

                  for (var j = 0; j < array_comunidas_ras.length; j++) {
                    if (array_comunidas_ras[j].comunidad_k == comunidades[i].comunidad_k) {
                      comunidades_ras_nuevo.push(array_comunidas_ras[j]);
                    }
                  }
                }
              }
            }

            localStorage.setItem("comunidades_ras", JSON.stringify(comunidades_ras_nuevo));
          }
        },
        error: function error(e) {}
      });
    },
    guardarImagenRuta: function guardarImagenRuta() {
      var self = this;
      var urlRa = "";
      var thumbRa = "";
      var url_page = window.location.href.split("/");
      var ra_k = url_page[url_page.length - 1];

      if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
        document.addEventListener("deviceready", onDeviceReady, false);

        function onDeviceReady() {
          self.set("botonDescargaGeneral", true);
          var detalleRutas = localStorage.getItem("detalleRutas");

          if (detalleRutas != null) {
            var objeto = JSON.parse(detalleRutas);

            for (var i = 0; i < objeto.length; i++) {
              if (objeto[i].ra_k == ra_k) {
                urlRa = objeto[i].imagen.replace(window.constant.BASEURL, "");
                thumbRa = window.constant.BASEURL + "system/application/libraries/thumbs.php?s=" + urlRa + "&w=96&h=96&d=3";
                self.send("descargarRecurso", thumbRa, null, null, "imageRa");
              }
            }

            ;
          }

          if (urlRa == "") {
            if (localStorage.getItem("imagen_ra") != null) {
              urlRa = localStorage.getItem("imagen_ra").replace(window.constant.BASEURL, "");
              thumbRa = window.constant.BASEURL + "system/application/libraries/thumbs.php?s=" + urlRa + "&w=96&h=96&d=3";
              self.send("descargarRecurso", thumbRa, null, null, "imageRa");
            }
          }
        }
      }
    },
    datosUsuario: function datosUsuario() {
      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'usuario/datospersonales/',
        type: 'POST',
        async: false,
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        success: function success(r) {
          var usuario = JSON.parse(r);
          self.set("usuario", usuario);
        },
        error: function error(e) {}
      });
    },
    sincronizacionPendiente: function sincronizacionPendiente() {
      var self = this;
      var ra_descargadas = localStorage.getItem('ra_descargadas');
      self.set("calificarCurso", false); // Verificamos si existe sincronizacion programada cuando tengamos datos locales

      if (ra_descargadas != null) {
        Ember.$.ajax({
          url: constant.APIURL + 'lms/raprendizaje/sincronizacionPendiente',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          data: {
            ra_k: self.get("ra_k")
          },
          async: false,
          dataType: "json",
          contentType: 'application/x-www-form-urlencoded',
          success: function success(r) {
            // Sincronizacion pendiente?
            if (r.success) {
              var rutas = JSON.parse(ra_descargadas);

              for (var i = 0; i < rutas.length; i++) {
                if (rutas[i][0].ra_k == self.get("ra_k")) {
                  Ember.$("#modal-sincronizacion-pendiente").modal("show");
                  setTimeout(function () {
                    sincronizar(self.get("ra_k"));
                  }, 1000);
                }
              }
            }
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    },
    existeTrackinRa: function existeTrackinRa() {
      var self = this;
      var url_page = window.location.href.split("/");
      var id = url_page[url_page.length - 1];
      self.set("ra_k", id);
      Ember.$.ajax({
        url: constant.APIURL + 'lms/raprendizaje/existeTrackinRa',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        data: {
          ra_k: id
        },
        async: false,
        dataType: "json",
        contentType: 'application/x-www-form-urlencoded',
        success: function success(r) {
          // Sincronizacion pendiente?
          if (r.success) {
            self.set("calificarCurso", false);
            var ra_descargadas = localStorage.getItem('ra_descargadas');
            var datosDeRuta = false;

            if (ra_descargadas != null) {
              var rutas = JSON.parse(ra_descargadas); // Verificamos que existan datos de la ruta actual

              for (var i = 0; i < rutas.length; i++) {
                if (rutas[i][0].ra_k == id) {
                  var jsonObjetosSincronizacion = localStorage.getItem("objetosSincronizacion");

                  if (jsonObjetosSincronizacion != null) {
                    var objetosSincronizacion = JSON.parse(jsonObjetosSincronizacion);

                    for (var m = 0; m < objetosSincronizacion.length; m++) {
                      // Sincronizamos solo si el objeto no se ha sincronizando
                      if (objetosSincronizacion[m].ra_k == id) {
                        datosDeRuta = true;
                      }
                    }
                  }
                }
              }

              if (!datosDeRuta) Ember.$("#modal-sincronizacion-pc").modal("show");else Ember.$("#modal-sincronizacion-movil").modal("show");
            } else Ember.$("#modal-sincronizacion-pc").modal("show");
          } else {
            // Si no hay pendientes revisamos si existe sincronizacion programada
            self.sincronizacionPendiente();
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    setEvaluacion: function setEvaluacion(r, self) {
      self.set('evaluacion', r);
    },
    LoadData: function LoadData() {
      var url_page = window.location.href.split("/");
      var id = url_page[url_page.length - 1]; //url_page.substring(url_page.lastIndexOf('/') + 1);

      var self = this;
      window.isHabilitarCalifRa = false;

      if (self.get("procesoAnterior")) {
        self.set("procesoAnterior", false);
        Ember.$.ajax({
          url: constant.APIURL + 'lms/alumno/listarrutas/listarmisras',
          type: 'POST',
          xhrFields: {
            withCredentials: true
          },
          contentType: 'application/x-www-form-urlencoded',
          data: 'params={"ra_k":' + id + '}',
          success: function success(r) {
            var response = JSON.parse(r);
            var ra;
            Object.keys(response.data).forEach(function (idx) {
              ra = response.data[idx].intento_ra;
            });
            var usuario = window.datosPersonales;
            Ember.$.ajax({
              url: constant.APIURL + 'lms/raprendizaje/listarProgreso',
              //async:false,
              type: 'POST',
              xhrFields: {
                withCredentials: true
              },
              contentType: 'application/x-www-form-urlencoded',
              data: 'usuario_k=' + usuario.usuario_k + '&llave_propietario_k=' + id + '&intento_ra=' + ra + '&cat=is null',
              success: function success(r) {
                var respons = JSON.parse(r);
                var primerScoDisponible = false;
                var raObject = respons.ra;
                Object.keys(respons.data).forEach(function (idx) {
                  // Por defecto asiganmos el path vacio para modo offline
                  respons.data[idx].path = "";

                  if (respons.data[idx].numero_intentos === null) {
                    respons.data[idx].numero_intentos = "0";
                  }

                  if (respons.data[idx].habilitar_calificar_ra == 1) {
                    window.datosScoredUser = {
                      "id_user": respons.ra.usuario_k,
                      "id_course": respons.ra.ra_k,
                      "evaluation": idx
                    };
                  } else {
                    window.datosScoredUser = {
                      "id_user": -2,
                      "id_course": -2,
                      "evaluation": idx
                    };
                  }

                  var habilitarDescarga = false; //Label de los botones

                  if (respons.data[idx].categoria_objeto_aprendizaje === 'Autoevaluación' || respons.data[idx].categoria_objeto_aprendizaje === 'Evaluaciones' || respons.data[idx].categoria_objeto_aprendizaje === 'Finales') {
                    respons.data[idx].categoria_objeto_aprendizaje += ' / Evaluación';
                    respons.data[idx].boton_label = "APLICAR";
                  } else if (respons.data[idx].categoria_objeto_aprendizaje === 'Nafin') {
                    respons.data[idx].categoria_objeto_aprendizaje += ' / Curso Online';
                    respons.data[idx].boton_label = "INGRESAR";
                  } else if (respons.data[idx].categoria_objeto_aprendizaje === 'Nafin' && respons.data[idx].estatus === "8") {
                    respons.data[idx].categoria_objeto_aprendizaje += ' / Curso Online';
                    respons.data[idx].boton_label = "VISUALIZAR";
                  } else if (respons.data[idx].categoria_objeto_aprendizaje === 'Encuestas') {
                    respons.data[idx].categoria_objeto_aprendizaje += ' / Encuesta';
                    respons.data[idx].boton_label = "APLICAR";
                  } else {
                    respons.data[idx].categoria_objeto_aprendizaje += ' / Actividades';
                    respons.data[idx].boton_label = "APLICAR";
                  }

                  respons.data[idx].disponible = true;

                  if (respons.data[idx].scos != null) {
                    // Si es el primer elemento o el anterior es un SCO
                    if (idx == 0) habilitarDescarga = true; // Si el elemento anterior es aprobado o es SCO
                    else if (respons.data[idx - 1].estatus == 8 && respons.data[idx - 1].tabla_recurso != "lms_cursos_online") {
                        habilitarDescarga = true;
                        primerScoDisponible = true;
                      } else if (primerScoDisponible) {
                        //if((respons.data[idx-1].estatus == 8 || respons.data[idx-1].tabla_recurso  == "lms_cursos_online"))
                        habilitarDescarga = true; //else 
                        //if(respons.data[idx].estatus == null)
                        //	habilitarDescarga = true;
                      }
                    if (respons.data[idx].estatus == 8) habilitarDescarga = false;
                    respons.data[idx].boton_descarga = habilitarDescarga;
                    respons.data[idx].ra_k = respons.ra.ra_k;
                    if (respons.data[idx].boton_descarga && respons.data[idx].disponibilidad_descarga == 0) respons.data[idx].boton_descarga = false;

                    if (respons.data[idx].boton_descarga) {
                      respons.data[idx].boton_descarga = self.verificarCursoDescargado(respons.data[idx]);
                    } else {
                      respons.data[idx].disponible = false;
                    }
                  } //fix 	


                  if (respons.data[idx].numero_intentos.indexOf('/') != -1) {
                    respons.data[idx].numero_intentos = respons.data[idx].numero_intentos.split('/')[0];
                  } //Labels con base en código THT


                  if (respons.data[idx].tabla_recurso == "lms_cursos_presencial" && respons.data[idx].estatus == 7) {
                    respons.data[idx].boton_label = "VER DETALLES";
                  }

                  if (respons.data[idx].tabla_recurso == "lms_evaluaciones" || respons.data[idx].tabla_recurso == "lms_encuestas") {
                    if (respons.data[idx].estatus == 1 || respons.data[idx].estatus == 3 || respons.data[idx].estatus == 9 && parseInt(respons.data[idx].numero_intentos) > 0) {
                      respons.data[idx].boton_label = "APLICAR";
                    }
                  }

                  if (respons.data[idx].tabla_recurso == "lms_cursos_online") {
                    if (respons.data[idx].estatus == 1 || respons.data[idx].estatus == 3 || respons.data[idx].estatus == 5 || respons.data[idx].estatus == null || respons.data[idx].estatus == 9 && parseInt(respons.data[idx].numero_intentos) > 0) {
                      respons.data[idx].ra_k = respons.ra.ra_k;
                      respons.data[idx].boton_label = "INGRESAR";
                      respons.data[idx].ra_k = respons.ra.ra_k;
                    } else if (respons.data[idx].estatus == 8 && respons.data[idx].habilitar_calificar_ra == 1) {
                      respons.data[idx].boton_label = "VISUALIZAR";
                      respons.data[idx].habilitar_calificar = "1";
                      respons.data[idx].habilitar_calificar_ra = respons.data[idx].habilitar_calificar;

                      if (self.get("calificarCurso")) {
                        /*$('#modal_grade').modal({
                        	backdrop: 'static',
                        	keyboard: false
                        });
                        $('#modal_grade').on('hidden.bs.modal', function () {
                        	document.location.reload();
                        });
                        $('#modal_grade').modal();*/
                      }
                    }
                  }

                  respons.data[idx].visible = true; //Estatis

                  if (respons.data[idx].estatus === "1") {
                    respons.data[idx].estatus_evaluacion = "En curso";
                  } else if (respons.data[idx].estatus === "2") {
                    respons.data[idx].estatus_evaluacion = "Cancelado";
                  } else if (respons.data[idx].estatus === "3") {
                    respons.data[idx].estatus_evaluacion = "Reabierto";
                  } else if (respons.data[idx].estatus === "4") {
                    respons.data[idx].estatus_evaluacion = "Completo";
                    respons.data[idx].visible = false;
                  } else if (respons.data[idx].estatus === "5") {
                    respons.data[idx].estatus_evaluacion = "Incompleto";
                  } else if (respons.data[idx].estatus === "6") {
                    respons.data[idx].estatus_evaluacion = "Preinscripción";
                  } else if (respons.data[idx].estatus === "7") {
                    respons.data[idx].estatus_evaluacion = "Inscrito";
                  } else if (respons.data[idx].estatus === "8") {
                    respons.data[idx].estatus_evaluacion = "Aprobado";
                    respons.data[idx].boton_label = "VISUALIZAR";

                    if (respons.data[idx].tabla_recurso == 'lms_cursos_online') {
                      respons.data[idx].visible = true;
                    } else {
                      respons.data[idx].visible = false;
                    }
                  } else if (respons.data[idx].estatus === '9') {
                    respons.data[idx].estatus_evaluacion = "Reprobado";
                  } else if (respons.data[idx].estatus === '10') {
                    respons.data[idx].estatus_evaluacion = "No atendido";
                  } else if (respons.data[idx].estatus === '11') {
                    respons.data[idx].estatus_evaluacion = "Visitado";
                  } else if (respons.data[idx].estatus === '12') {
                    respons.data[idx].estatus_evaluacion = "No asistió";
                  } else {
                    respons.data[idx].estatus_evaluacion = "En espera";
                  }

                  if (parseInt(respons.data[idx].numero_intentos) > parseInt(respons.data[idx].limite_intentos)) {
                    respons.data[idx].lock = true;
                    respons.data[idx].leyend = 'Número de intentos alcanzado';
                  } else {
                    Object.keys(respons.data).forEach(function (idx2) {
                      //if (respons.data[idx2].dependencias != null) {
                      if (respons.data[idx2].propietario_recurso_k == respons.data[idx].dependencias) {
                        respons.data[idx].lock = true;
                        respons.data[idx].lock = true;

                        if (respons.data[idx2].estatus == '8' || respons.data[idx2].estatus == '4') {
                          //console.log("Entra aqui " + respons.data[idx].dependencias + " => " + respons.data[idx2].propietario_recurso_k + " tiene estatus :" + respons.data[idx2].estatus)
                          respons.data[idx].lock = false;
                          respons.data[idx].leyend = '';
                        } else {
                          respons.data[idx].lock = true;
                          respons.data[idx].leyend = 'Disponible al concluir <b>' + respons.data[idx2].nombre + '</b>';

                          if (respons.data[idx].estatus == '1' || respons.data[idx].estatus == '8' || respons.data[idx].estatus == '4' || respons.data[idx].estatus == '5' || respons.data[idx].estatus == '9') {
                            respons.data[idx].lock = false;
                            respons.data[idx].leyend = '';
                          }
                        }
                      } //}

                    });

                    if (respons.data[idx - 1] != null && respons.data[idx - 1] != undefined) {
                      if (respons.data[idx].estatus == 8) {
                        respons.data[idx].boton_descarga = false;
                        respons.data[idx - 1].boton_label = "VISUALIZAR";
                        respons.data[idx - 1].boton_descarga = false;
                      }
                    }
                  } //ICONos


                  if (respons.data[idx].tabla_recurso == 'lms_evaluaciones' && respons.data[idx].evaluacion_autodiagnostica == "1") {
                    respons.data[idx].icon = 'img/iconos_cursos/evaluacion.png';
                  } else if (respons.data[idx].tabla_recurso == 'lms_cursos_online') {
                    respons.data[idx].icon = 'img/iconos_cursos/scorm.png';
                  } else if (respons.data[idx].tabla_recurso == 'lms_encuestas') {
                    respons.data[idx].icon = 'img/iconos_cursos/encuesta.png';
                  } else if (respons.data[idx].tabla_recurso == 'lms_evaluaciones') {
                    respons.data[idx].icon = 'img/iconos_cursos/evaluacion.png';
                  } else if (respons.data[idx].tabla_recurso == 'lms_actividad') {
                    respons.data[idx].icon = 'img/iconos_cursos/actividad.png';
                  } else {
                    respons.data[idx].icon = 'img/iconos_cursos/presencial.png';
                  } // Actualizamos offline  solo si el curso es tipo "lms_cursos_online"


                  if (respons.data[idx].tabla_recurso == "lms_cursos_online" && (respons.data[idx].estatus == '1' || respons.data[idx].estatus == '4' || respons.data[idx].estatus == '5' || respons.data[idx].estatus == '9')) {
                    var avancesScorm = self.avancesScormVarsEvaluacion(respons.data[idx]); //if(avancesScorm.length){

                    self.send("actualizarAvanceEvaluacionOffline", respons.data[idx], avancesScorm, respons); //}
                  } else if (respons.data[idx].estatus == '8') {
                    self.send("actualizarLabelsAvanceEvaluacionOffline", respons.data[idx]);
                  }

                  Ember.$("#modal-sincronizacion-pendiente").modal("hide");
                  self.set("calificarCurso", true);
                  Ember.$('#loading').fadeOut('slow');
                  /*let thumbIcon = window.constant.BASEURL + "system/application/libraries/thumbs.php?s=" + respons.data[idx].icon + "&w=60&h=60&d=3";
                  self.send("toDataURL",thumbIcon,"temporalIcon");
                  	respons.data[idx].icon = self.get("temporalIcon");*/
                });
                if (respons.data != null) localStorage.setItem("progreso", JSON.stringify(respons.data));
                self.setEvaluacion(respons.data, self);
                setTimeout(function () {
                  self.set("procesoAnterior", true);
                  self.LoadData();
                }, 8000);
              },
              error: function error(e) {
                console.log(e);
              }
            });
          },
          error: function error(e) {
            console.log(e);
          }
        });
      }
    }
  }, _defineProperty(_EmberComponent$exte, "didRender", function didRender() {
    var self = this;
    Ember.$('button[data-lock="false"]').removeAttr('disabled');
  }), _defineProperty(_EmberComponent$exte, "verificarCursoDescargado", function verificarCursoDescargado(evaluacion) {
    var ra_descargadas = localStorage.getItem('ra_descargadas');
    var descargar = true;
    var self = this;

    if (ra_descargadas != null) {
      var rutas = JSON.parse(ra_descargadas);

      for (var i = 0; i < rutas.length; i++) {
        //console.log("rutas => ",rutas[i]);
        if (rutas[i][0].ra_k == evaluacion.ra_k) {
          var objetos = rutas[i][0].objetos;

          for (var j = 0; j < objetos.length; j++) {
            if (objetos[j].propietario_recurso_k == evaluacion.propietario_recurso_k && objetos[j].intento_ra == evaluacion.intento_ra) descargar = false;else if (objetos[j].intento_ra < evaluacion.intento_ra) {
              // Eliminamos objetos obsoletos
              var rutasNuevas = JSON.parse(ra_descargadas);
              var rutasObjeto = [];

              for (var k = 0; k < rutasNuevas.length; k++) {
                if (rutas[i][0].ra_k == rutasNuevas[k][0].ra_k) {
                  var objetosNuevaRuta = rutasNuevas[k][0].objetos;

                  for (var l = 0; l < objetosNuevaRuta.length; l++) {
                    localStorage.removeItem("avanceCurso_" + objetosNuevaRuta[l].propietario_recurso_k);
                  }
                } else {
                  rutasObjeto.push(rutasNuevas[k]);
                }
              }

              localStorage.setItem('ra_descargadas', JSON.stringify(rutasObjeto)); // Eliminamos avances obsoletos

              var jsonObjetosSincronizacion = localStorage.getItem("objetosSincronizacion");
              var nuevoObjetoSincronizacion = [];

              if (jsonObjetosSincronizacion != null) {
                var objetosSincronizacion = JSON.parse(jsonObjetosSincronizacion);

                for (var m = 0; m < objetosSincronizacion.length; m++) {
                  // Sincronizamos solo si el objeto no se ha sincronizando
                  if (objetosSincronizacion[m].ra_k == rutas[i][0].ra_k) {} else {
                    nuevoObjetoSincronizacion.push(objetosSincronizacion[m]);
                  }
                }
              }

              localStorage.setItem('objetosSincronizacion', JSON.stringify(nuevoObjetoSincronizacion));
              self.verificarCursoDescargado(evaluacion);
              return false;
            }
          }
        }
      }
    }

    return descargar;
  }), _defineProperty(_EmberComponent$exte, "avancesScormVarsEvaluacion", function avancesScormVarsEvaluacion(evaluacion) {
    var self = this;
    var avances = "";
    var ra_descargadas = localStorage.getItem('ra_descargadas');

    if (ra_descargadas != null) {
      Ember.$.ajax({
        url: window.constant.APIURL + 'lms/raprendizaje/avancesScormEspecifico',
        type: "post",
        data: {
          intento_ra: evaluacion.intento_ra,
          lms_scorm_k: evaluacion.scos
        },
        async: false,
        dataType: "json",
        xhrFields: {
          withCredentials: true
        },
        success: function success(response) {
          if (response.success) avances = response.data;
        },
        error: function error(e) {
          console.log(e);
        }
      });
    }

    return avances;
  }), _defineProperty(_EmberComponent$exte, "actions", {
    regresarCursos: function regresarCursos() {
      var self = this;
      var secciones = document.location.href.split("index");
      secciones = document.location.href.split("cursos");
      window.location.href = secciones[0] + "cursos";
      window.location.reload();
    },
    cerrarModal: function cerrarModal(id) {
      Ember.$("#" + id).modal("hide");
    },
    descartarAvances: function descartarAvances() {
      self = this;
      self.set("calificarCurso", true);
    },
    continuarConAvances: function continuarConAvances() {
      var self = this;
      var ra_descargadas = localStorage.getItem('ra_descargadas');
      var jsonObjetosSincronizacion = localStorage.getItem("objetosSincronizacion");
      Ember.$("#modal-sincronizacion").modal("hide");

      if (jsonObjetosSincronizacion != null) {
        var objetosSincronizacion = JSON.parse(jsonObjetosSincronizacion);

        for (var i = 0; i < objetosSincronizacion.length; i++) {
          // Sincronizamos solo si el objeto no se ha sincronizando
          if (objetosSincronizacion[i].ra_k == self.get("ra_k")) {
            Ember.$.ajax({
              url: window.constant.APIURL + 'lms/raprendizaje/programarSincronizacion',
              type: "post",
              data: {
                ra_k: objetosSincronizacion[i].ra_k,
                grupo_k: objetosSincronizacion[i].grupo_k,
                propietario_recurso_k: objetosSincronizacion[i].propietario_recurso_k,
                dispositivo_uuid: device.uuid,
                intento_ra: objetosSincronizacion[i].intento_ra
              },
              async: false,
              dataType: "json",
              xhrFields: {
                withCredentials: true
              },
              success: function success(response) {
                if (response.success) {
                  // Si el usuario entra desde app
                  if (ra_descargadas != null) {
                    window.location.reload();
                  }
                }
              },
              error: function error(e) {
                console.log(e);
              }
            });
          }
        }

        Ember.$("#modal-sincronizacion-movil").modal("hide");
      } else {
        Ember.$("#modal-sincronizacion-movil").modal("hide");
      }
    },
    tamanoTotalStorage: function tamanoTotalStorage() {
      var _lsTotal = 0,
          _xLen,
          _x;

      for (_x in localStorage) {
        if (!localStorage.hasOwnProperty(_x)) {
          continue;
        }

        _xLen = (localStorage[_x].length + _x.length) * 2;
        _lsTotal += _xLen;
        console.log(_x.substr(0, 50) + " = " + (_xLen / 1024).toFixed(2) + " KB");
      }

      ;
      console.log("Total = " + (_lsTotal / 1024).toFixed(2) + " KB");
    },
    toDataURL: function toDataURL(src, e) {
      var obj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var self = this;
      var xhr = new XMLHttpRequest();

      xhr.onload = function () {
        var reader = new FileReader();

        reader.onloadend = function () {
          self.set(e, reader.result);

          if (obj != null) {
            var arrayComunidadesObject = localStorage.getItem("comunidades_ras") != null ? JSON.parse(localStorage.getItem("comunidades_ras")) : [];

            for (var i = 0; i < arrayComunidadesObject.length; i++) {
              if (arrayComunidadesObject[i].comunidad_k == obj.comunidad_k) {
                arrayComunidadesObject[i].imagen = reader.result;
                localStorage.setItem("comunidades_ras", JSON.stringify(arrayComunidadesObject));
              }
            }
          }
        };

        reader.readAsDataURL(xhr.response);
      };

      xhr.open('GET', src);
      xhr.responseType = 'blob';
      xhr.send();
    },
    actualizarLabelsAvanceEvaluacionOffline: function actualizarLabelsAvanceEvaluacionOffline(evaluacion) {
      var ra_descargadas = localStorage.getItem('ra_descargadas');

      if (ra_descargadas != null) {
        var rutas = JSON.parse(ra_descargadas);

        for (var i = 0; i < rutas.length; i++) {
          //console.log("rutas => ",rutas[i]);
          if (rutas[i][0].ra_k == evaluacion.ra_k) {
            var objetos = rutas[i][0].objetos;

            for (var j = 0; j < objetos.length; j++) {
              if (objetos[j].propietario_recurso_k == evaluacion.propietario_recurso_k) {
                rutas[i][0].objetos[j]["estatus_evaluacion"] = evaluacion.estatus_evaluacion;
                rutas[i][0].objetos[j]["estatus"] = evaluacion.estatus;
                rutas[i][0].objetos[j]["boton_label"] = evaluacion.boton_label;
                rutas[i][0].objetos[j]["numero_intentos"] = evaluacion.numero_intentos;
              }
            }
          }
        }

        localStorage.setItem('ra_descargadas', JSON.stringify(rutas));
      }
    },
    actualizarAvanceEvaluacionOffline: function actualizarAvanceEvaluacionOffline(evaluacion, avancesScorm, responseListarProgreso) {
      var ra_descargadas = localStorage.getItem('ra_descargadas');

      if (ra_descargadas != null) {
        var rutas = JSON.parse(ra_descargadas);

        for (var i = 0; i < rutas.length; i++) {
          //console.log("rutas => ",rutas[i]);
          if (rutas[i][0].ra_k == evaluacion.ra_k) {
            var objetos = rutas[i][0].objetos; // porcentaje

            rutas[i][0].porcentaje_avance = parseInt(responseListarProgreso.ra.porcentaje_avance); // progreso

            rutas[i][0].progreso = JSON.stringify(responseListarProgreso.data);

            for (var j = 0; j < objetos.length; j++) {
              if (objetos[j].propietario_recurso_k == evaluacion.propietario_recurso_k) {
                //Actualizamos los avances del objeto
                //objetos.scormvars = avancesScorm;
                rutas[i][0].objetos[j]["lock"] = evaluacion.lock;
                rutas[i][0].objetos[j]["leyend"] = evaluacion.leyend;
                rutas[i][0].objetos[j]["estatus_evaluacion"] = evaluacion.estatus_evaluacion;
                rutas[i][0].objetos[j]["estatus"] = evaluacion.estatus;
                rutas[i][0].objetos[j]["boton_label"] = evaluacion.boton_label;
                rutas[i][0].objetos[j]["numero_intentos"] = evaluacion.numero_intentos;
                rutas[i][0].objetos[j]["recurso_alumno_resumen_k"] = evaluacion.recurso_alumno_resumen_k;
                rutas[i][0].objetos[j]["recurso_alumno_resultado_k"] = evaluacion.recurso_alumno_resultado_k;
                rutas[i][0].objetos[j]["porcentaje_obtenido"] = evaluacion.porcentaje_obtenido;
                rutas[i][0].objetos[j]["scormvars"] = [];
                rutas[i][0].objetos[j]["scormvars"] = avancesScorm;
                var dateObj = new Date();
                rutas[i][0].objetos[j]["fechaActualizacion"] = dateObj;
              }
            }
          }
        } // Actualizamos la informacion local - Offline


        localStorage.setItem('ra_descargadas', JSON.stringify(rutas));
      }
    },
    tracking: function tracking(evaluacion) {
      Ember.$.ajax({
        url: window.constant.APIURL + 'lms/raprendizaje/iniciarTrackingOffline',
        type: "post",
        data: {
          intento_ra: evaluacion.intento_ra,
          ra_k: evaluacion.ra_k,
          grupo_k: evaluacion.grupo_k,
          propietario_recurso_k: evaluacion.propietario_recurso_k,
          dispositivo_uuid: device.uuid,
          dispositivo_model: device.model,
          dispositivo_platform: device.platform,
          dispositivo_version: device.version,
          dispositivo_manufacturer: device.manufacturer
        },
        async: false,
        dataType: "json",
        xhrFields: {
          withCredentials: true
        },
        success: function success(response) {
          if (response.success) {}
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    guardarCursoLocal: function guardarCursoLocal(pathCurso) {
      var self = this;
      var evaluacion = self.get("evaluacionDescarga");
      var ra_descargadas = localStorage.getItem('ra_descargadas'); // != null ? localStorage.getItem('ra_descargadas'): "{}";

      var objetoMisRa = [];
      var jsonMisRa = "";
      var arrayComunidadesObject = localStorage.getItem("comunidades_ras") != null ? JSON.parse(localStorage.getItem("comunidades_ras")) : [];
      var misComunidades = self.get("misComunidades");
      var arrayRas = [];
      var ArrayobjetoMisRa = {};
      var existeRa = false;
      var idsComunidadesRa = [];
      var existeComunidadStorage;
      var jsonScormVars = []; // Obtenemos los avances del curso scorm

      jsonScormVars = self.avancesScormVarsEvaluacion(evaluacion); // Otenemos las comunidades asociadas a la ruta de aprendizaje

      Ember.$.ajax({
        url: window.constant.APIURL + 'lms/raprendizaje/listarComunidadesRa',
        type: "post",
        data: {
          ra_k: evaluacion.ra_k
        },
        async: false,
        dataType: "json",
        xhrFields: {
          withCredentials: true
        },
        success: function success(response) {
          if (response.success) {
            // Creamos un item comunidad por cada rsgistro
            Ember.$.each(response.data, function (i, v) {
              var agregarComunidadRuta = false;

              for (var k = 0; k < misComunidades.length; k++) {
                if (misComunidades[k].comunidad_k == v.comunidad_k) {
                  agregarComunidadRuta = true;
                }
              }

              if (agregarComunidadRuta) {
                idsComunidadesRa.push(v.comunidad_k);
                existeComunidadStorage = false;

                for (var j = 0; j < arrayComunidadesObject.length; j++) {
                  if (arrayComunidadesObject[j].comunidad_k == v.comunidad_k) existeComunidadStorage = true;
                }

                var NobjetoComunidades = {
                  comunidad_k: v.comunidad_k,
                  nombre_comunidad: v.nombre_comunidad,
                  imagen: ""
                };
                var imagenComunidad = window.constant.BASEURL + "system/application/libraries/thumbs.php?s=" + v.img + "&w=192&h=192&d=3";

                if (!existeComunidadStorage) {
                  arrayComunidadesObject.push(NobjetoComunidades); // Almacenamos en localstorage la informacion de las comunidades

                  localStorage.setItem("comunidades_ras", JSON.stringify(arrayComunidadesObject));
                  self.send("descargarRecurso", imagenComunidad, evaluacion, NobjetoComunidades);
                }
              } //self.send("toDataURL",imagenComunidad,"imgComunidad"+v.comunidad , NobjetoComunidades);								

            });
          }
        },
        error: function error(e) {
          console.log(e);
        }
      }); // Creamos el objeto principal de la ruta

      var newRa = {
        ra_k: evaluacion.ra_k,
        imagen_ra: self.get("imageRa"),
        ra_nombre: Ember.$("#cursoNombre").text(),
        ra_descripcion: Ember.$("#cursoDescripcion").text(),
        porcentaje_avance: Ember.$("#porcentaje_avance").text(),
        ranking: Ember.$("#ranking").text(),
        detalles: localStorage.getItem("curso"),
        progreso: localStorage.getItem("progreso"),
        objetos: [],
        comunidades: idsComunidadesRa
      }; // En caso de que existan datos previos de las rutas

      if (ra_descargadas != null) {
        ArrayobjetoMisRa = JSON.parse(ra_descargadas); // Buscamos si existe la ruta (ra_k) almacenada previamente

        var raEnLocalStorage = {};

        for (var i = 0; i < ArrayobjetoMisRa.length; i++) {
          existeRa = false;

          if (ArrayobjetoMisRa[i][0].ra_k == evaluacion.ra_k) {
            raEnLocalStorage = ArrayobjetoMisRa[i][0];
            existeRa = true;

            if (existeRa) {
              if (JSON.stringify(raEnLocalStorage.comunidades) == JSON.stringify(idsComunidadesRa)) {//console.log("Las comunidades son las mismas");
              } else {//console.log("Se han modificado las comunidades");
                }
            }
          }
        }
      }

      evaluacion.path = pathCurso;
      evaluacion.scormvars = jsonScormVars;
      var dateObj = new Date();
      evaluacion.fechaActualizacion = dateObj; // Validamos que exista el stroage de las rutas

      if (ra_descargadas != null && ra_descargadas != undefined) {
        //ArrayobjetoMisRa = JSON.parse(ra_descargadas);
        // Buscamos si existe la ruta (ra_k) almacenada previamente
        var existeRutaEnObjeto = false;
        ra_descargadas = localStorage.getItem('ra_descargadas');
        var rasEnComunidad = JSON.parse(ra_descargadas);

        for (var j = 0; j < rasEnComunidad.length; j++) {
          // Verificamos que exista la ruta
          if (rasEnComunidad[j][0].ra_k == evaluacion.ra_k) {
            console.log("Existe la ruta");
            var existeObjeto = false; // Actualizamos detallesde ruta

            rasEnComunidad[j][0].porcentaje_avance = Ember.$("#porcentaje_avance").text(); //self.get("porcentaje_avance");

            rasEnComunidad[j][0].ranking = Ember.$("#ranking").text();
            rasEnComunidad[j][0].imagen_ra = self.get("imageRa");
            rasEnComunidad[j][0].ra_nombre = Ember.$("#cursoNombre").text();
            rasEnComunidad[j][0].ra_descripcion = Ember.$("#cursoDescripcion").text();
            rasEnComunidad[j][0].detalles = localStorage.getItem("curso");
            rasEnComunidad[j][0].progreso = localStorage.getItem("progreso"); // Validamos que no exista el objeto previamente en ruta

            Ember.$.each(rasEnComunidad[j][0].objetos, function (j, v) {
              if (v.propietario_recurso_k == evaluacion.propietario_recurso_k) existeObjeto = true;
            });

            if (!existeObjeto) {
              //rasEnComunidad[j][0][0].objetos.push(evaluacion);
              rasEnComunidad[j][0].objetos.push(evaluacion);
            } else console.log("Existe el objeto");

            existeRutaEnObjeto = true;
            break;
          }
        }

        if (!existeRutaEnObjeto) {
          console.log("No existe la ruta");
          newRa.objetos.push(evaluacion);
          objetoMisRa.push(newRa);
          ArrayobjetoMisRa.push(objetoMisRa);
          jsonMisRa = JSON.stringify(ArrayobjetoMisRa);
        } else {
          console.log("Existe la ruta");
          jsonMisRa = JSON.stringify(rasEnComunidad);
        }
      } // Si no existe la creamos
      else {
          localStorage.setItem('ra_descargadas', '[]');
          self.send("guardarCursoLocal", pathCurso);
          return false;
        }

      localStorage.setItem('ra_descargadas', jsonMisRa); //console.log("Json de objetos descargados => ", localStorage.getItem("ra_descargadas"));
    },
    eliminarZip: function eliminarZip(path, zip) {
      window.resolveLocalFileSystemURL(path, function (dir) {
        dir.getFile(zip, {
          create: false
        }, function (fileEntry) {
          fileEntry.remove(function (file) {
            console.log("Zip temporal removido");
          }, function (error) {
            console.log("Error al eliminar zip temporal : " + error.code);
          }, function () {
            console.log("El zip temporal no existe");
          });
        });
      });
    },
    descargarCurso: function descargarCurso() {
      var self = this;
      var evaluacion = self.get("evaluacionDescarga");
      var uri = encodeURI(window.constant.BASEURL + self.zip_url);
      var completeUnzip = false;
      var pathDownloadsCurso = "";

      if (device.platform == "Android") {
        pathDownloadsCurso = cordova.file.dataDirectory + "downloads/" + evaluacion.ra_k + "/" + evaluacion.propietario_recurso_k;
      } else {
        pathDownloadsCurso = cordova.file.documentsDirectory + "downloads/" + evaluacion.ra_k + "/" + evaluacion.propietario_recurso_k;
      }

      var temporalNameZip = "/tmp_" + evaluacion.propietario_recurso_k + ".zip";
      var fileURLZip = pathDownloadsCurso + temporalNameZip; // Path to the file

      var ZipPath = ""; // Handle the result of the process

      var StatusCallback = function StatusCallback(status) {
        if (status == 0) {
          Ember.$(".status-descarga").text("Curso descargado con éxito");
          Ember.$(".porcentaje-descarga").addClass("d-none");
          Ember.$(".modal").find(".modal-footer").show();
          Ember.$(".btn-descargar").hide(); //self.send("eliminarZip",pathDownloadsCurso,temporalNameZip);

          self.send("listDir", pathDownloadsCurso); // Iniciamos tracking

          self.send("tracking", evaluacion);
          self.send("guardarCursoLocal", pathDownloadsCurso);
        } else if (status == -1) {
          alert("Ha ocurrido un error durante la descarga, por favor intente mas tarde...");
          window.location.reload();
          Ember.$("#modal-confirmacion-descarga").modal("hide");
        }
      }; // Mostramos los tiempos de descarga


      var ProgressCallback = function ProgressCallback(progressEvent) {
        var percent = Math.round(progressEvent.loaded / progressEvent.total * 100);

        if (percent % 5 == 0) {
          Ember.$(".porcentaje-descarga").find(".progress-bar").data("aria-valuenow", percent);
          Ember.$(".porcentaje-descarga").find(".porcentaje-texto").text(percent + "%");
        }

        Ember.$(".porcentaje-descarga").find(".progress-bar").attr("style", "width:" + percent + "%"); // Display progress in the console : 8% ...

        if (percent == 100 && !completeUnzip) {
          completeUnzip = true;
        }
      };

      Ember.$(".mensaje-confirmacion-descarga").html("");
      Ember.$(".status-descarga").text("Iniciando descarga, por favor espere ...");
      Ember.$(".porcentaje-descarga").removeClass("d-none");
      Ember.$(".porcentaje-descarga").find(".progress-bar").data("aria-valuenow", "0");
      Ember.$(".porcentaje-descarga").find(".progress-bar").attr("style", "width:0%");
      Ember.$(".porcentaje-descarga").find(".porcentaje-texto").text("0%");
      Ember.$(".modal").find(".modal-footer").hide();
      var fileTransfer = new FileTransfer();
      fileTransfer.download(uri, fileURLZip, function (entry) {
        Ember.$(".status-descarga").text("Descomprimiendo curso:");
        ZipPath = entry.toURL(); // Unzip it !

        window.zip.unzip(ZipPath, pathDownloadsCurso, StatusCallback, ProgressCallback);
      }, function (error) {
        console.log("Ha ocurrido un error durante la descompresion, por favor intente mas tarde...");
        Ember.$("#modal-confirmacion-descarga").modal("hide");
        console.log("download error source " + error.source);
        console.log("download error target " + error.target);
        console.log("download error code" + error.code);
      });

      fileTransfer.onprogress = function (progressEvent) {
        if (progressEvent.lengthComputable) {
          var perc = Math.floor(progressEvent.loaded / progressEvent.total * 100);
          Ember.$(".status-descarga").text("Descargando curso:");

          if (perc % 5 == 0) {
            Ember.$(".porcentaje-descarga").find(".progress-bar").data("aria-valuenow", perc);
            Ember.$(".porcentaje-descarga").find(".progress-bar").attr("style", "width:" + perc + "%");
          }

          Ember.$(".porcentaje-descarga").find(".porcentaje-texto").text(perc + "%");
        } else {
          Ember.$(".status-descarga").text("Descargando curso:");
          Ember.$(".porcentaje-descarga").find(".progress-bar").data("aria-valuenow", "0");
          Ember.$(".porcentaje-descarga").find(".progress-bar").attr("style", "width:0%");
          Ember.$(".porcentaje-descarga").find(".porcentaje-descarga").text("0%");
        }
      };
    },
    descargarRecurso: function descargarRecurso(url) {
      var evaluacion = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var obj = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var name = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
      console.log("=========");
      var fileTransfer = new FileTransfer();
      var uri = encodeURI(url);
      var path = url.split("/");
      var recurso = path[path.length - 1].split("&")[0];
      var pathDownloads = "";
      var self = this;
      var localPath = "temporal";

      if (evaluacion != null) {
        localPath = evaluacion.ra_k + "/" + evaluacion.propietario_recurso_k;
      }

      if (device.platform == "Android") {
        console.log("Android");
        pathDownloads = cordova.file.dataDirectory + "downloads/" + localPath;
      } else {
        console.log("IOS");
        pathDownloads = cordova.file.documentsDirectory + "downloads/" + localPath;
      }

      var fileURL = pathDownloads + "/" + recurso;
      fileTransfer.download(uri, fileURL, function (entry) {
        console.log("Descargado: " + entry.toURL());

        if (name != "") {
          //self.set(name,window.Ionic.WebView.convertFileSrc(entry.toURL()).replace("undefined/",""));
          self.set(name, "downloads/" + localPath + "/" + recurso);

          if (name == "imageRa") {
            var ra_descargadas = localStorage.getItem('ra_descargadas');
            var actualizacionRutasDescargadas = [];

            if (ra_descargadas != null) {
              var rutas = JSON.parse(ra_descargadas);

              for (var i = 0; i < rutas.length; i++) {
                if (rutas[i][0].ra_k == self.get("ra_k")) {
                  rutas[i][0].imagen_ra = "downloads/" + localPath + "/" + recurso;
                }

                actualizacionRutasDescargadas.push(rutas[i]);
              }

              localStorage.setItem('ra_descargadas', JSON.stringify(actualizacionRutasDescargadas));
            }
          }
        }

        if (obj != null) {
          var arrayComunidadesObject = localStorage.getItem("comunidades_ras") != null ? JSON.parse(localStorage.getItem("comunidades_ras")) : [];

          for (var _i = 0; _i < arrayComunidadesObject.length; _i++) {
            if (arrayComunidadesObject[_i].comunidad_k == obj.comunidad_k) {
              arrayComunidadesObject[_i].imagen = "downloads/" + localPath + "/" + recurso; //window.Ionic.WebView.convertFileSrc(entry.toURL()).replace("undefined/","")

              localStorage.setItem("comunidades_ras", JSON.stringify(arrayComunidadesObject));
            }
          }
        }
      }, function (error) {
        //alert("Error " + error.source + " " + error.target + " " + error.code);
        self.set("botonDescargaGeneral", false);
        console.log("Ha ocurrido un error durante la descarga, por favor intente mas tarde...");
        console.log("download error source " + error.source);
        console.log("download error target " + error.target);
        console.log("download error code" + error.code);
      });
    },
    listDir: function listDir(path) {
      window.resolveLocalFileSystemURL(path, function (fileSystem) {
        var reader = fileSystem.createReader();
        reader.readEntries(function (entries) {
          console.log(entries);
        }, function (err) {
          console.log(err);
        });
      }, function (err) {
        console.log(err);
      });
    },
    validarDescarga: function validarDescarga(evaluacion) {
      console.log("Evaluacion => ", evaluacion);
      var self = this;
      var zip = evaluacion.nombre_zip;
      self.set("evaluacionDescarga", evaluacion); //self.send("guardarCursoLocal");
      //return false;

      Ember.$.ajax({
        url: window.constant.APIURL + 'lms/raprendizaje/validarDescargaZip',
        type: "post",
        data: {
          zip: zip,
          llave_recurso_k: evaluacion.llave_recurso_k
        },
        async: false,
        dataType: "json",
        xhrFields: {
          withCredentials: true
        },
        success: function success(response) {
          if (response.success) {
            self.zip_url = response.zip_url; // calculamos espacio disponible

            cordova.exec(function (bytes) {
              var mbs = parseInt(bytes / 1024);
              var gbs = "";
              var diskSpace = "";

              if (mbs > 1024) {
                gbs = parseFloat(mbs / 1024);
                diskSpace = gbs.toFixed(2) + "GB";
              } else diskSpace = mbs + "MB";

              if (mbs < response.size) {
                Ember.$(".mensaje-confirmacion-descarga").html("El curso pesa " + response.size + "MB" + ", espacio disponible en dispositivo " + diskSpace + ". <br> Lamentablemente no se puede completar la descarga");
                Ember.$("#modal-confirmacion-error").modal("show");
              } else {
                Ember.$(".mensaje-confirmacion-descarga").html("El curso pesa " + response.size + "MB" + ", espacio disponible en dispositivo " + diskSpace + ". <br> ¿Desea continuar con la descarga?");
                Ember.$("#modal-confirmacion-descarga").modal("show");
              }

              Ember.$(".btn-descargar").show();
            }, function (error) {
              return "Error: " + error;
            }, "File", "getFreeDiskSpace", []);
          } else {
            alert("El curso no esta disponible en este momento");
          }
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    validaciones_pregunta_inicial: function validaciones_pregunta_inicial() {
      if (Ember.$('.carousel-item.active').data('obligatorio') == 1 || Ember.$('.carousel-item.active').data('obligatorio') == null) {
        Ember.$('#btn_nxt').prop("disabled", true);
        setTimeout(function () {
          var checked = false;
          Ember.$('.carousel-item.active input').click(function () {
            Ember.$('.carousel-item.active input').each(function () {
              if (Ember.$(this).is(":checked")) {
                checked = true;
              }
            });

            if (checked) {
              //if ($('.carousel-item.active input:checked').prop('checked') === true) {
              Ember.$('#btn_nxt').prop("disabled", false);
              Ember.$("#btn_end").prop("disabled", false);
            } else {
              Ember.$('#btn_nxt').prop("disabled", true);
              Ember.$("#btn_end").prop("disabled", true);
            }
          });
        }, 800);
        setTimeout(function () {
          Ember.$('.carousel-item.active textarea').each(function () {
            Ember.$(this).on('keyup mouseup change', function () {
              if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0) {
                Ember.$('#btn_nxt').prop("disabled", false);
                Ember.$("#btn_end").prop("disabled", false);
              } else {
                Ember.$('#btn_nxt').prop("disabled", true);
                Ember.$("#btn_end").prop("disabled", true);
              }
            });
          });
          Ember.$('.carousel-item.active input[type=number]').each(function () {
            Ember.$(this).on('keyup mouseup change', function () {
              var pass = 0;
              Ember.$('.carousel-item.active input[type=number]').each(function () {
                if (Ember.$(this).val() !== '' && Ember.$(this).val() != 0 && parseInt(Ember.$(this).val()) <= parseInt(Ember.$(this).attr('max')) && parseInt(Ember.$(this).val()) >= parseInt(Ember.$(this).attr('min'))) {
                  pass++;
                }
              });

              if (pass == Ember.$('.carousel-item.active input[type=number]').length) {
                Ember.$('#btn_nxt').prop("disabled", false);
                Ember.$("#btn_end").prop("disabled", false);
              } else {
                Ember.$('#btn_nxt').prop("disabled", true);
                Ember.$("#btn_end").prop("disabled", true);
              }
            });
          });
          Ember.$('.carousel-item.active select').each(function () {
            Ember.$(this).change(function () {
              if (Ember.$(this).val() !== '') {
                Ember.$('#btn_nxt').prop("disabled", false);
                Ember.$("#btn_end").prop("disabled", false);
              }
            });
          });
        }, 800);
      }
    },
    scorm: function scorm() {
      Ember.$("#modal-scorm-saving").modal("show");
      Ember.$(".modal-dialog #ifr").attr("onload", "$('#modal-scorm-saving').modal('hide');");
      setTimeout(function () {
        Ember.$('#modal-scorm').modal('hide');
        Ember.$(".modal-dialog #ifr").attr("src", "");
      }, 1000);
    },
    evaluacion: function evaluacion(event) {
      var self = this;
      var propietario_recurso_k = Ember.$(event.target).attr('id');
      var noIntentos = Ember.$(event.target).data('intetos');
      var noIntentosActual = noIntentos;
      var evaluacion = this.get('evaluacion');
      var idxobjeto = Ember.$(event.target).data('idx');
      var objeto = evaluacion[idxobjeto];
      var url_page = window.location.href;
      var id = url_page.substring(url_page.lastIndexOf('/') + 1);

      if (idxobjeto == datosScoredUser.evaluation) {
        window.isHabilitarCalifRa = true;
      } else {
        window.isHabilitarCalifRa = false;
      } //window open post


      if (objeto.tabla_recurso == 'lms_cursos_online') {
        var url = constant.BASEURL + 'index.php/scorm/lanzarscorm';

        if (objeto.estatus == '8' || objeto.estatus == '4') {
          var form = '<form action="' + url + '" method="post" id="formScorm">' + '<input type="hidden" name="lms_scorm_k" value="' + objeto.scos + '" onclick="test()"/>' + '<input type="hidden" name="scorm_test" value="1" />' + '<input type="hidden" name="propietario_recurso_k" value="' + objeto.propietario_recurso_k + '" />' + //'<input type="hidden" name="propietario_recurso_k" value="'+objeto.noIntentosActual+'" />'+
          '</form>'; // App 

          if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
            var options = "";

            if (device.platform == "Android") {
              options = 'location=yes,hidden=no,closebuttoncaption=Volver a  curso,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
            } else {
              options = 'location=no,hidden=no,closebuttoncaption=Volver a  curso,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
            }

            var pageContentUrl = 'data:text/html;base64,' + btoa('<html><head></head><body>' + form + '<script type="text/javascript">document.getElementById("formScorm").submit();</script></body></html>');
            var ref = cordova.InAppBrowser.open(pageContentUrl, '_blank', options);
            ref.addEventListener('loadstart', function (event) {});
            ref.addEventListener('loadstop', function (event) {});
            ref.addEventListener('exit', function (event) {});
          } else {
            // Web
            form = Ember.$(form);
            form.attr("target", '_blank');
            Ember.$('body').append(form);
            form.submit();
          }
        } else {
          var form = '<form id="formScorm" action="' + url + '" method="post" >' + '<input type="hidden" name="lms_scorm_k" value="' + objeto.scos + '" />' + '<input type="hidden" name="scorm_test" value="0" />' + '<input type="hidden" name="propietario_recurso_k" value="' + objeto.propietario_recurso_k + '" />' + //'<input type="hidden" name="propietario_recurso_k" value="'+objeto.noIntentosActual+'" />'+
          '</form>'; // App

          if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
            var options = "";

            if (device.platform == "Android") {
              options = 'location=yes,hidden=no,closebuttoncaption=Volver a curso,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
            } else {
              options = 'location=no,hidden=no,closebuttoncaption=Volver a curso,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
            }

            var pageContentUrl = 'data:text/html;base64,' + btoa('<html><head></head><body>' + form + '<script type="text/javascript">document.getElementById("formScorm").submit();</script></body></html>');
            var ref = cordova.InAppBrowser.open(pageContentUrl, '_blank', options);
            ref.addEventListener('loadstart', function (event) {});
            ref.addEventListener('loadstop', function (event) {});
            ref.addEventListener('exit', function (event) {});
          } else {
            // Web
            form = Ember.$(form);
            form.attr("target", '_blank');
            Ember.$('body').append(form);
            form.submit();
          }
        } //$("#modal-scorm").modal("show");

      } else {
        window.buttoIngresar = Ember.$(event.target).attr('id');
        var data = 'propietario_recurso_k=' + objeto.propietario_recurso_k + '&intento_ra=' + objeto.intento_ra + '&grupo_k=' + id + '&usuario_k=' + '&numero_intentos=';
        var segmento = 'evaluacion';

        if (objeto.tabla_recurso == 'lms_encuestas') {
          segmento = 'encuesta';
        }

        Ember.$.ajax({
          url: constant.APIURL + 'lms/' + segmento + '/validaraplicar',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          xhrFields: {
            withCredentials: true
          },
          success: function success(response) {
            var r = JSON.parse(response);
            self.get('bus').trigger('setearReglas', r);
            Ember.$('#btn_nxt').attr('hidden', false);
            Ember.$('#btn_prv').attr('hidden', true);
            Ember.$('#btn_close').attr('hidden', true);
            localStorage.setItem('recurso_alumno_resultado_k', r.recurso_alumno_resultado_k);
            var preguntas = r.data.preguntas;
            Ember.$('#contadorPreguntas').html('Pregunta 1 de ' + preguntas.length);
            var html = '';
            var findOne = 0;

            if (preguntas.length == 1) {
              Ember.$('#btn_nxt').attr('hidden', true);
              Ember.$('#btn_end').removeAttr('hidden');
            }

            Object.keys(preguntas).forEach(function (idx) {
              html += '<div class="carousel-item" data-tabla="' + objeto.tabla_recurso + '" data-obligatorio=' + preguntas[idx].obligatoria + '>';
              html += '<div class="row">';
              html += '<div class="col-12">';
              html += '<br><h2 class="font-responsive" data-usuario="' + objeto.usuario_k + '" data-propietario="' + propietario_recurso_k + '" data-tipo="' + preguntas[idx].tipo_pregunta_k + '">' + preguntas[idx].pregunta + '</h2>';
              html += '</div>';
              html += '</div>'; // Tipo de variables traidas directamente del back
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_SI_NO',              1 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_UNICA',              2 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_MULTIPLE',           3 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_LISTA_DESPLEGABLE',  4 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_RELACIONAR_COLUMNAS',5 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_PORCENTAJE',         6 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_PUNTUACION',         7 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_ABIERTA',            8 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_ORDENAR_RESPUESTA',  9 
              //'LMS_PREGUNTAS_TIPO_RESPUESTA_CALIFICAR_NIVELES',  10

              if (preguntas[idx].obligatoria == '1') {
                Ember.$('#btn_nxt').prop("disabled", true);
              }

              if (preguntas[idx].tipo_pregunta_k == 1) {
                Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
                  html += '<div class="form-check py-1">';
                  html += '<input class="form-check-input" type="radio" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
                  html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
                  html += preguntas[idx].respuestas[idx_respuesta].descripcion;
                  html += '</label>';
                  html += '</div>';
                });
              } else if (preguntas[idx].tipo_pregunta_k == 2) {
                Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                  html += '<div class="form-check py-1">';
                  html += '<input class="form-check-input" type="radio" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
                  html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
                  html += preguntas[idx].respuestas[idx2].descripcion;
                  html += '</label>';
                  html += '</div>';
                });
              } else if (preguntas[idx].tipo_pregunta_k == 3) {
                Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                  html += '<div class="form-check py-1">';
                  html += '<input class="form-check-input" type="checkbox" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
                  html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
                  html += preguntas[idx].respuestas[idx2].descripcion;
                  html += '</label>';
                  html += '</div>';
                });
              } else if (preguntas[idx].tipo_pregunta_k == 4) {
                html += '<div class="form-group">';
                html += '<select class="form-control" data-quest="' + preguntas[idx].pregunta_k + '">'; //html += '<select class="form-control" data-quest="'+preguntas[idx].pregunta_k+'">'

                html += '<option class="form-check-input" data-orden=""></option>';
                Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                  html += '<option class="form-check-input" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '">' + preguntas[idx].respuestas[idx2].descripcion + '</option>';
                });
                html += '</select>';
                html += '</div>';
              } else if (preguntas[idx].tipo_pregunta_k == 5) {
                var left = [];
                var right = [];
                Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                  if (parseInt(preguntas[idx].respuestas[idx2].orden, 10) % 2) {
                    left.push(preguntas[idx].respuestas[idx2]);
                  } else {
                    right.push(preguntas[idx].respuestas[idx2]);
                  }
                });
                html += '<div class="row">';
                html += '<div class="col-6">';
                html += '<div class="font-responsive" data-quest="' + preguntas[idx].pregunta_k + '">';

                for (var i = 0; i < left.length; i++) {
                  html += '<div class="font-responsive mb-2" id="' + left[i].respuesta_k + '" data-correcta="' + left[i].correcta + '" data-orden="' + left[i].orden + '"><i>' + (i + 1) + '</i>) <span class="font-responsive">' + left[i].descripcion + '</span></div>';
                }

                html += '</div>';
                html += '</div>';
                var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
                html += '<div class="col-6">';
                html += '<div class="font-responsive">';

                for (var i = 0; i < right.length; i++) {
                  html += '<div clas="font-responsive">';
                  html += '<i>' + letters[i] + ')</i>' + ' ' + '<span class="font-responsive">' + right[i].descripcion + '</span>';
                  html += '<select class="form-control select2" id="' + right[i].respuesta_k + '" data-orden=' + right[i].orden + '>';
                  html += '<option class="form-check-input" type="checkbox" id="" value="0" data-correcta="" data-orden=""></option>';

                  for (var j = 0; j < left.length; j++) {
                    html += '<option value="' + left[j].respuesta_k + '">';
                    html += '<span class="font-responsive">' + left[j].descripcion + '</span>';
                    html += '</option>';
                  }

                  html += '</select>';
                  html += '</div>';
                }

                html += '</div>';
                html += '</div>';
                html += '</div>';
              } else if (preguntas[idx].tipo_pregunta_k == 6) {
                Object.keys(preguntas[idx].respuestas).forEach(function (idx2) {
                  html += '<div class="form-check py-1">';
                  html += '<input max="100" min="0" class="form-check-input" type="number" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx2].respuesta_k + '" value="' + preguntas[idx].respuestas[idx2].correcta + '" data-orden="' + preguntas[idx].respuestas[idx2].orden + '" data-quest="' + preguntas[idx].pregunta_k + '"/>';
                  html += '<label class="form-check-label" for="' + preguntas[idx].respuestas[idx2].respuesta_k + '">';
                  html += preguntas[idx].respuestas[idx2].descripcion;
                  html += '</label>';
                  html += '</div>';
                });
              } else if (preguntas[idx].tipo_pregunta_k == 7) {
                Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
                  html += '<div class="form-check py-1">';
                  html += '<label class="form-check-label margin_ud_10px" for="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '">';
                  html += preguntas[idx].respuestas[idx_respuesta].descripcion;
                  html += '<input class="form-control" max="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta_k + '" min="1" type="number" name="_' + idx + '" id="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta].correcta + '" value="' + preguntas[idx].respuestas[idx_respuesta].puntuacion * 10 + '" data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '"  data-quest="' + preguntas[idx].pregunta_k + '"/>';
                  html += '</label>';
                  html += '</div>';
                });
              } else if (preguntas[idx].tipo_pregunta_k == 8) {
                html += '<div class="form-check py-1">';
                html += '<textarea class="form-control" name="_' + idx + '" id="' + preguntas[idx].respuestas[0].respuesta_k + '" value="' + preguntas[idx].respuestas[0].descripcion + '" data-quest="' + preguntas[idx].pregunta_k + '"></textarea>';
                html += '<label class="form-check-label">';
                html += preguntas[idx].respuestas[0].descripcion;
                html += '</label>';
                html += '</div>';
              } else if (preguntas[idx].tipo_pregunta_k == 9) {
                Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta) {
                  var contador = 1;
                  html += '<div class="row">';
                  html += '<div class="col-12 col-lg-8 margin_ud_10px">';
                  html += '<label class="form-check-label" for="customCheck1" data-respuesta="' + preguntas[idx].respuestas[idx_respuesta].respuesta_k + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta].orden + '">';
                  html += preguntas[idx].respuestas[idx_respuesta].descripcion;
                  html += '</label>';
                  html += '</div>';
                  html += '<div class="col-12 col-lg-4 margin_ud_10px">';
                  html += '<select class="form-control" data-quest="' + preguntas[idx].pregunta_k + '">';
                  html += '<option class="form-check-input value="0" data-correcta="" data-rc="" data-orden="">Seleccione una opción</option>';
                  Object.keys(preguntas[idx].respuestas).forEach(function (idx_respuesta_ordenar) {
                    html += '<option class="form-check-input" id="' + preguntas[idx].respuestas[idx_respuesta_ordenar].respuesta_k + '" value="' + preguntas[idx].respuestas[idx_respuesta_ordenar].puntuacion + '" data-correcta="' + preguntas[idx].respuestas[idx_respuesta_ordenar].correcta + '" data-rc="' + preguntas[idx].respuestas[idx_respuesta].respuesta_correcta + '" data-orden="' + preguntas[idx].respuestas[idx_respuesta_ordenar].orden + '">' + contador++ + '</option>';
                  });
                  html += '</select>';
                  html += '</div>';
                  html += '</div>';
                });
              } else {
                html += 'Tipo de pregunta no implementado ' + preguntas[idx].tipo_pregunta_k;
              }

              html += '</div>';
            });
            var estadoPregunta = false;
            Ember.$('.bd-evaluacion-modal-lg').modal({
              backdrop: 'static',
              keyboard: false
            });
            Ember.$('#carouselQuestions').html(html);
            Ember.$('.carousel-item:first-child').addClass('active');
            var d = new Date();
            Ember.$('#date_time_start').html("Inicio: " + d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + " " + d.getHours() + ":" + ('0' + d.getMinutes()).slice(-2));
            Ember.$('.form-check-input').click(function () {
              Ember.$('#btn_nxt').prop("disabled", false);
              Ember.$("#btn_end").prop("disabled", false);
            });
            /*$('select.select2').select2({
            	dropdownParent: $('#modal_container_evaluacion'),
                placeholder:'Seleccione una opción',
            	allowClear: true,
            	width:'80%',
            	selectOnClose: true,
            	closeOnSelect: true
            });*/

            self.send('validaciones_pregunta_inicial');
          },
          error: function error(e) {
            console.error(e);
            Ember.$('#carouselQuestions').html('Estamos teniendo problemas tecnicos');
          }
        });
      }
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});