define("comunidades-nafin/components/events-coards-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this;
      var d = new Date();
      var day = d.getDate();
      var month = d.getMonth();

      if (month < 10) {
        month = '0' + month;
      }

      var year = d.getFullYear();
      var date = new Date();
      var newDate = new Date(date.setDate(date.getDate() + 30));
      var newDay = newDate.getDate();
      var newYear = newDate.getFullYear();
      var newMonth = newDate.getMonth();
      Ember.$.ajax({
        url: constant.APIURL + 'calendario/listarProximosEventos',
        type: 'POST',
        xhrFields: {
          withCredentials: true
        },
        contentType: 'application/x-www-form-urlencoded',
        data: 'params={"start":"' + year + '-' + month + '-' + day + '","end":"' + newYear + '-' + newMonth + '-' + newDay + '"}',
        success: function success(response) {
          var data = JSON.parse(response); //console.log(data.data.length);

          var dataEventos;

          if (data.data == '') {
            dataEventos = 0;
          } else {
            dataEventos = 1;
          }

          self.formatter(data.data, self);
        },
        error: function error(e) {
          console.log(e);
        }
      });
    },
    pmFormatter: function pmFormatter(time24) {
      var ts = time24;
      var H = +ts.substr(0, 2);
      var h = H % 12 || 12;
      h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours

      var ampm = H < 12 ? " AM" : " PM";
      ts = h + ts.substr(2, 3) + ampm;
      return ts;
    },
    //Dar formato deseado a mes y fechas
    formatter: function formatter(data, self) {
      if (data != undefined) {
        Object.keys(data).forEach(function (idx) {
          var date = data[idx].start.split('-');
          var dateEnd = data[idx].end.split('-');
          var month = date[1];
          var monthEnd = dateEnd[1];
          data[idx].yearFormath = date[0];
          data[idx].monthFormath = self.monthFormatter(month);
          data[idx].dayFormath = date[2];
          data[idx].endyearFormath = dateEnd[0];
          data[idx].endmonthFormath = self.monthFormatter(monthEnd);
          data[idx].enddayFormath = dateEnd[2];
          data[idx].formatt_hora = self.pmFormatter(data[idx].hora);
          data[idx].formatt_hora_fin = self.pmFormatter(data[idx].hora_fin);
        });
      }

      self.setMonth(data, self);
    },
    // Asignar el més en el que nos encontramos
    monthFormatter: function monthFormatter(month) {
      if (month === "01") {
        return "Enero";
      } else if (month === "02") {
        return "Febrero";
      } else if (month === "03") {
        return "Marzo";
      } else if (month === "04") {
        return "Abril";
      } else if (month === "05") {
        return "Mayo";
      } else if (month === "06") {
        return "Junio";
      } else if (month === "07") {
        return "Julio";
      } else if (month === "08") {
        return "Agosto";
      } else if (month === "09") {
        return "Septiembre";
      } else if (month === "10") {
        return "Octubre";
      } else if (month === "11") {
        return "Noviembre";
      } else {
        return "Diciembre";
      }
    },
    setMonth: function setMonth(data, self) {
      self.set('event', data);
      setTimeout(function () {
        Ember.$('#carouselEventos .carousel-item:first-child').addClass('active');
        Ember.$('#carouselEventos').carousel({
          interval: 5000
        });
      }, 1000);
      Ember.$("#carouselEventos").removeClass("d-none");
    },
    actions: {
      prevSlide: function prevSlide() {
        Ember.$('.carousel-inner.box_shado.events-cards').carousel('prev');
      },
      nextSlide: function nextSlide() {
        Ember.$('.carousel-inner.box_shado.events-cards').carousel('next');
      }
    }
  });

  _exports.default = _default;
});