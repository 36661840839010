define("comunidades-nafin/templates/nom-035-stps/reportes/administrador/factores-riesgo-psicosocial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s7wynu5f",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Factores de Riesgo Psicologico\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/reportes/administrador/factores-riesgo-psicosocial.hbs"
    }
  });

  _exports.default = _default;
});