define("comunidades-nafin/components/webinars-cards", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      moment.updateLocale('es', {
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      });
      $.ajax({
        url: constant.FIREBASE_WEBIN,
        type: 'POST',
        data: {
          method: 'get_webinars_open'
        },
        success: function success(r) {
          if (r.status) {
            var open_webinars = r.data.conferences;
            $.ajax({
              url: constant.FIREBASE_WEBIN,
              type: 'POST',
              data: {
                method: 'get_webinars_user',
                data: {
                  id: self.session.userData.usuario_k
                }
              },
              success: function success(r) {
                var suscriber_webinars = r.data;

                if (suscriber_webinars) {
                  Object.keys(open_webinars).forEach(function (key) {
                    if (suscriber_webinars[open_webinars[key].id]) {
                      open_webinars[key].suscribed = true;
                    }
                  });
                }

                self.set('next_webinars', open_webinars);
              }
            });
          }
        }
      });
      $.ajax({
        url: constant.FIREBASE_WEBIN,
        type: 'POST',
        data: {
          method: 'get_webinars_close'
        },
        success: function success(r) {
          if (r.status) {
            self.set('previous_webinars', r.data.conferences);
          }

          $('#loading').fadeOut('slow');
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set("webinar_state", true);
      $(".text-webinar").addClass("selected-option-menu");
    },
    actions: {
      anchorTo: function anchorTo(path) {
        //event.preventDefault();
        var target_offset = $("#" + path).offset();
        var target_top = target_offset.top;
        $('html, body').animate({
          scrollTop: target_top
        }, 1500, 'easeInSine'); ///alert(document.location.href);
        //document.location.href = document.location.href.split("#")[0] + "#" + path;
        //return false;
      },
      viewmore: function viewmore(event) {
        $(event.target).closest(".webinar-details").find(".hidden-element").removeClass("hidden-standalone-view-more");
        $(event.target).closest(".webinar-details").find(".ocultar-ver-mas").removeClass("d-none");
        $(event.target).addClass("d-none");
      },
      hideviewmore: function hideviewmore(event) {
        $(event.target).closest(".webinar-details").find(".hidden-element").addClass("hidden-standalone-view-more");
        $(event.target).closest(".webinar-details").find(".ocultar-ver-mas").addClass("d-none");
        $(event.target).closest(".webinar-details").find(".ver-mas").removeClass("d-none");
      },
      new_webinar: function new_webinar() {
        this.set("webinar_state", true);
      },
      old_webinar: function old_webinar() {
        this.set("webinar_state", false);
      },
      inscribir: function inscribir(url) {
        if (window.hasOwnProperty("cordova") || (typeof cordova === "undefined" ? "undefined" : _typeof(cordova)) == "object" || !!window.cordova) {
          var options = "";

          if (device.platform == "Android") {
            options = 'location=yes,hidden=no,closebuttoncaption=Volver a webinars,hidenavigationbuttons=yes,hideurlbar=yes,footer=no,footercolor=' + window.color_theme;
          } else {
            options = 'location=no,hidden=no,closebuttoncaption=Volver a webinars,hidenavigationbuttons=yes,hideurlbar=yes,footer=false,footercolor=' + window.color_theme;
          }

          var pageContentUrl = url;
          var ref = cordova.InAppBrowser.open(pageContentUrl, '_blank', options);
          ref.addEventListener('loadstart', function (event) {});
          ref.addEventListener('loadstop', function (event) {});
          ref.addEventListener('exit', function (event) {});
        } else {
          window.open(url, "_blank");
        }
      },
      gotowebinar: function gotowebinar(id) {
        $('#loading').fadeIn('slow');
        $.ajax({
          url: constant.FIREBASE_WEBIN,
          type: 'POST',
          data: {
            method: 'enter_webinar',
            data: {
              webinar: {
                id: id
              },
              user: this.session.userData
            }
          },
          success: function success(r) {
            $('#loading').fadeOut('slow');
            var a = document.createElement('a');
            a.href = r.data.enter_uri;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        });
      },
      getwebinarvideo: function getwebinarvideo(id, name) {
        this.set("webinarName", name);
        $.ajax({
          url: constant.FIREBASE_WEBIN,
          type: 'POST',
          data: {
            method: 'get_webinars_detail',
            data: {
              webinar: {
                id: id
              }
            }
          },
          success: function success(r) {
            $('#loading').fadeOut('slow');

            if (r.status && r.data) {
              var webinar = r.data.conference;
              var src = r.data.recording_url; //$('#webinar-'+webinar.id).parent().parent().addClass('text-center');
              //let html= '<div class="col-12 text-center">'+webinar.recording_iframe+'</div>';
              //$('#webinar-'+webinar.id).append(html);
              //$('#webinar-'+webinar.id).find('.no-video').parent().parent().remove();*/

              if (webinar.recording_iframe != undefined) {
                $("#modal-webinar").modal("show");
                $(".modal-dialog").find(".cargando").html('Cargando video, por favor espere');
                var iframe = webinar.recording_iframe.replace("<iframe", '<iframe onload="$(\'.modal-dialog .cargando\').html(\'\')"'); //iframe = iframe.replace('"560"','"100%"');
                //$(".modal-dialog #ifr").attr("src",src);

                $(".modal-dialog").find("#ifr").html(iframe);
                $(".modal-dialog").find("#ifr").find("iframe").addClass("iframe-fullwidth");
              } else {
                alert("El video no se encuentra disponible por el momento");
              }
            }
          }
        }); //$("#modal-webinar:first .show").find("cargando").html('Cargando video, por favor espere');
      },
      endVideo: function endVideo() {
        //$(".modal-dialog #ifr").attr("src",'');
        //setTimeot(function(){
        $(".modal-dialog").find("#ifr").html("");
        $("#modal-webinar").modal("hide"); //},2000)
        //
      },
      registerwebinar: function registerwebinar(id) {
        $('#loading').fadeIn('slow');
        $.ajax({
          url: constant.FIREBASE_WEBIN,
          type: 'POST',
          data: {
            method: 'suscribe_webinar',
            data: {
              webinar: {
                id: id
              },
              user: this.session.userData
            }
          },
          success: function success(r) {
            $('#loading').fadeOut('slow');
          }
        });
      },
      unregisterwebinar: function unregisterwebinar(id) {
        $('#loading').fadeIn('slow');
        $.ajax({
          url: constant.FIREBASE_WEBIN,
          type: 'POST',
          data: {
            method: 'unsuscribe_webinar',
            data: {
              webinar: {
                id: id
              },
              user: this.session.userData
            }
          },
          success: function success(r) {
            $('#loading').fadeOut('slow');
          }
        });
      },
      changesuscription: function changesuscription(id) {
        var status = $('#webinar-' + id).find('input[type="checkbox"]').is(':checked');

        if (status) {
          this.send('registerwebinar', id);
        } else {
          this.send('unregisterwebinar', id);
        }
      },
      funcionalidadNoIntegrada: function funcionalidadNoIntegrada() {
        alert("funcionalidad aun no desarrollada");
      }
    }
  });

  _exports.default = _default;
});