define("comunidades-nafin/components/component-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bus: Ember.inject.service('event-chat'),
    usuario_k: 0,
    last_mensaje_k: 0,
    data_conversation: {},
    global_token_chat: '',
    chatsocket: {},
    user_reciver: 0,
    lastdata: null,
    token_general: 0,
    conversaciones_pendientes: 0,
    iniciar_socket: function iniciar_socket() {
      var self = this;
      var data_conversation = this.get('data_conversation');
      this.socket.chat.nuevo = true;

      if (data_conversation.token) {
        this.socket.chat.emit('switchRoom', data_conversation.token);
      } //Cargamos los eventos personales


      this.socket.socket.on('eventUser' + self.session.userData.usuario_k, function (data) {
        var conversaciones = self.get("conversaciones");
        conversaciones.forEach(function (v, i) {
          if (data_conversation.conversacion_k != null && !self.get('outChat')) {
            if (v.conversacion_k == data.conversacion_k && data.usuario_emisor_k != self.session.userData.usuario_k && data_conversation.conversacion_k != data.conversacion_k) {
              Ember.set(v, "newNotification", true);
              Ember.set(v, "pending", 1);
              Ember.$("#notificacionChat").show();
              var sendRecibido = {
                'conversacion_k': '' + data.conversacion_k + '',
                'usuario_emisor_k': self.session.userData.usuario_k,
                'usuario_creacion': self.session.userData.usuario_k,
                'usuario_receptor_k': data.usuario_emisor_k,
                'token': data.token,
                'token_conversacion': data.token_conversacion,
                "mensaje_k": data.mensaje_k
              };
              self.socket.chat.emit("sendRecibido", sendRecibido);
              self.send("contarConversacionesPendientes", self.get('conversaciones'));
            }
          } else if (v.conversacion_k == data.conversacion_k && data.usuario_emisor_k != self.session.userData.usuario_k) {
            Ember.set(v, "newNotification", true);
            Ember.set(v, "pending", 1);
            Ember.$("#notificacionChat").show();
            var sendRecibido = {
              'conversacion_k': '' + data.conversacion_k + '',
              'usuario_emisor_k': self.session.userData.usuario_k,
              'usuario_creacion': self.session.userData.usuario_k,
              'usuario_receptor_k': data.usuario_emisor_k,
              'token': data.token,
              'token_conversacion': data.token_conversacion,
              "mensaje_k": data.mensaje_k
            };
            self.socket.chat.emit("sendRecibido", sendRecibido);
            self.send("contarConversacionesPendientes", self.get('conversaciones'));
          }
        });
      }); // Detectamos la desconexion

      this.socket.chat.on('disconnect', function () {}); // Detectamos la conexion

      this.socket.chat.on('connect', function () {
        self.socket.chat.emit('switchRoom', self.get('token'));
      }); // Detectamos si el mensaje fue leido

      this.socket.chat.on('updateLeido', function (data) {
        if (self.get("data_conversation").conversacion_k == data.conversacion_k && self.session.userData.usuario_k != data.usuario_k) {
          setTimeout(function () {
            Ember.$(".icon-clock").addClass("icon-check").removeClass("icon-clock");
            Ember.$(".icon-check").addClass("icon-check-received").removeClass("icon-check");
            Ember.$(".icon-check-received").addClass("icon-check-readed").removeClass("icon-check-received");
          }, 1000);
        }
      }); //Detectamos los mensajes recibidos

      this.socket.chat.on('updateChat', function (data) {
        var html = '';
        data = JSON.parse(data);
        var usuario_k = self.session.userData.usuario_k;
        Ember.$('#chatInputBox').val('');

        if (usuario_k == data.usuario_emisor_k) {
          Ember.$(".icon-clock").addClass("icon-check").removeClass("icon-clock");
        } else {
          html = '<div class="yours messages">';
          html += '<div class="message last bg-theme">';
          html += data.mensaje;
          html += '</div></div>'; // Avisamos de recibido

          var sendRecibido = {
            'tipoInterfaz': "grouptabs",
            'generoMujer': 1,
            'conversacion_k': '' + data_conversation.conversacion_k + '',
            'usuario_emisor_k': self.session.userData.usuario_k,
            'usuario_creacion': self.session.userData.usuario_k,
            'usuario_receptor_k': data.usuario_emisor_k,
            'mensaje': data.mensaje,
            'token': data.token,
            'estatus': 1,
            'token_conversacion': data.token_conversacion,
            'foto': self.session.userData.foto,
            "nombre": self.session.userData.nombre,
            "mensaje_k": self.last_mensaje_k
          };
          self.socket.chat.emit("sendRecibido", sendRecibido);
          Ember.$('#scrollChat').append(html);
        } //self.reloadChat();


        if (!self.get('outChat')) {
          var element = document.getElementById('scrollChat');
          element.scrollTop = element.scrollHeight - element.clientHeight;
        } else {
          self.send('seeConvertation', data); //self.set("data_conversation",data);

          setTimeout(function () {
            var element = document.getElementById('scrollChat');
            element.scrollTop = element.scrollHeight - element.clientHeight;
          }, 1000);
        }
      });
      this.socket.socket.on('updateRecibido' + self.session.userData.usuario_k, function (data) {
        if (self.get('user_reciver') == data.usuario_emisor_k) {
          setTimeout(function () {
            Ember.$(".icon-check").addClass("icon-check-received").removeClass("icon-check");
          }, 1000);
        }

        try {
          self.set('token', data.token_conversacion);

          if (Ember.$('#chat_box_component').is(":hidden")) {
            Ember.$('#notificacionChat').show();
            var user = data.nombre;
            Ember.$('.card.bg_light_notification.margin_ud_10px:contains(' + user + ')').css("background-color", window.constant.COLOR);
          } else {
            var user = data.nombre;
            Ember.$('.card.bg_light_notification.margin_ud_10px:contains(' + user + ')').css("background-color", window.constant.COLOR);
          }
        } catch (e) {
          console.log(e);
        }
      }); //Recargamos lista de conversaciones en chat

      this.socket.socket.on('eventUserReloadChat' + self.session.userData.usuario_k, function (data) {
        self.loadChatConversations();
        if (self.get('outChat') == false) self.send("seeConvertation", self.get('data_conversation'));
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      this.set('outChat', true);

      if (Ember.$(window).width() > 991) {
        self.set('isDesktop', true);
        self.set('sizeMovile', false);
      } else {
        self.set('isDesktop', false);
        self.set('sizeMovile', true);
      }

      if (self.socket.chat.nuevo) {
        self.iniciar_socket();
      }

      self.loadChatConversations();
    },
    loadChatConversations: function loadChatConversations() {
      var self = this;
      Ember.$.ajax({
        url: constant.APIURL + 'conversaciones/listarConversaciones',
        type: 'POST',
        data: {
          "start": 0,
          "limit": 99
        },
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        xhrFields: {
          withCredentials: true
        },
        success: function success(response) {
          var r = JSON.parse(response);
          var data = r.data;

          if (data != undefined) {
            Object.keys(data).forEach(function (idx) {
              if (data[idx].foto.indexOf('http') !== 0) {
                data[idx].foto = constant.BASEURL + data[idx].foto;
              }

              if (data[idx].ultimoMensaje != undefined) {
                if (data[idx].ultimoMensaje.mensaje != undefined) data[idx].ultimoMensaje.mensaje = data[idx].ultimoMensaje.mensaje.length > 50 ? data[idx].ultimoMensaje.mensaje.substring(0, 50) + "..." : data[idx].ultimoMensaje.mensaje;else data[idx].ultimoMensaje.mensaje = "";
              } else {
                data[idx].ultimoMensaje.mensaje = "";
              }

              var sendRecibido = {
                'conversacion_k': '' + data[idx].conversacion_k + '',
                'usuario_emisor_k': self.session.userData.usuario_k,
                'usuario_creacion': self.session.userData.usuario_k,
                'usuario_receptor_k': data[idx].destinatario_k,
                'token': data[idx].token,
                'token_conversacion': data[idx].token,
                "mensaje_k": ""
              };

              if (data[idx].mensajes_no_vistos_usuario > 0 && self.session.userData.usuario_k != data[idx].propietario_k || data[idx].mensajes_no_vistos_propietario > 0 && self.session.userData.usuario_k == data[idx].propietario_k) {
                data[idx].pending = 1;
                self.conversaciones_pendientes++;
                self.socket.chat.emit('switchRoom', data[idx].token);
                self.socket.chat.emit("sendRecibido", sendRecibido);
                self.socket.chat.emit('switchRoom', null);
              } else {
                data[idx].pending = 0;
              }
            });
            self.set('conversaciones', data);
            self.set('usuario_k', self.session.userData.usuario_k);
            self.send("contarConversacionesPendientes", data);
          } //self.set('data_conversation', {});

        },
        error: function error(e) {
          console.error(e);
        }
      });
    },
    actions: {
      contarConversacionesPendientes: function contarConversacionesPendientes(conversaciones) {
        var conversaciones_pendientes = 0;
        conversaciones.forEach(function (v, i) {
          if (v.pending == 1) conversaciones_pendientes++;
        });

        if (conversaciones_pendientes) {
          Ember.$("#notificacionChat").show();
          Ember.$("#conversationsCount").text(conversaciones_pendientes);
        } else {
          Ember.$("#notificacionChat").hide();
        }
      },
      mensajeLeido: function mensajeLeido() {
        var self = this;

        if (self.get("data_conversation").conversacion_k != undefined) {
          var sendLeido = {
            conversacion_k: self.get("data_conversation").conversacion_k,
            usuario_k: self.session.userData.usuario_k
          };
          self.socket.chat.emit("sendLeido", sendLeido);
        }
      },
      seeConvertation: function seeConvertation(user) {
        var self = this;
        this.user = user;

        if (user.pending == 1) {
          Ember.set(user, "pending", 0);
          self.send("contarConversacionesPendientes", self.get('conversaciones'));
        }

        Ember.set(user, "newNotification", false);
        Ember.set(user, "mensajes_no_vistos_usuario", false);
        Ember.set(user, "mensajes_no_vistos_propietario", false); //if( this.socket.chat.chat_room != user.token ){
        //  this.socket.chat.chat_room = user.token;

        this.socket.chat.emit('switchRoom', null);
        this.socket.chat.emit('switchRoom', user.token); //}

        this.set('data_conversation', user); // event.stopPropagation(); // don't close the drop down when someone is on click

        this.set('outChat', false);
        this.set('usuario_k', user.usuario_k);
        var conversacion_k = self.get('data_conversation').conversacion_k;
        self.send("mensajeLeido");
        Ember.$('#chatInputBox').focus();
        Ember.$.ajax({
          url: constant.APIURL + 'conversaciones/agregarConversacionUsuario',
          type: 'POST',
          contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
          xhrFields: {
            withCredentials: true
          },
          async: false,
          data: 'usuario_k=' + self.session.userData.usuario_k,
          success: function success(response) {
            var data = JSON.parse(response).data[0];
            var token = data.token;
            self.set('token', token);
            Ember.$.ajax({
              url: constant.APIURL + 'conversaciones/listarConversacionesMensajes',
              type: 'POST',
              async: false,
              contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
              xhrFields: {
                withCredentials: true
              },
              data: 'conversacion_k=' + conversacion_k,
              success: function success(r) {
                var response = JSON.parse(r).data.reverse();
                Object.keys(response).forEach(function (idx) {
                  //Is my comento only ...
                  if (response[idx].usuario_emisor_k == self.get('usuario_k')) {
                    response[idx].myComment = true; //self.set('user_reciver', response[idx].usuario_receptor_k);
                  } else {
                    response[idx].myComment = false;
                  }

                  self.set('user_reciver', user.destinatario_k);
                  self.set('last_mensaje_k', response[idx].mensaje_k);
                });
                self.set('conversationHistoric', response);
                Ember.run.schedule('afterRender', function () {
                  var userfoto = user.foto;

                  if (user.foto.indexOf('http') != 0) {
                    userfoto = constant.BASEURL + user.foto;
                  }

                  Ember.$('#chatPictureUser').attr('src', String(userfoto));
                  Ember.$('#chatUserName').html(user.destinatario);
                  var element = document.getElementById('scrollChat');
                  element.scrollTop = element.scrollHeight - element.clientHeight;
                });
              },
              error: function error(e) {
                console.error(e);
              }
            });
          },
          error: function error(e) {
            console.error('NT: ', e);
          }
        });
      },
      sendComment: function sendComment() {
        var self = this;
        var msg = Ember.$('#chatInputBox').val(); //Si el mensaje no contiene texto

        if (msg.length == 0) return 0;

        if (msg === '') {
          return;
        } //event.stopPropagation();
        //var conversation = this.get('data_conversation');


        self.last_mensaje_k = parseInt(self.last_mensaje_k) + 1;
        var sendChat = {
          'tipoInterfaz': "grouptabs",
          'generoMujer': 1,
          'conversacion_k': self.get("data_conversation").conversacion_k,
          'usuario_emisor_k': self.get('usuario_k'),
          'usuario_creacion': self.get('usuario_k'),
          'usuario_receptor_k': self.get('user_reciver'),
          'mensaje': msg,
          'token': self.get('token'),
          'estatus': 1,
          'token_conversacion': self.get('data_conversation').token,
          'foto': self.session.userData.foto,
          "nombre": self.session.userData.nombre,
          "mensaje_k": self.last_mensaje_k
        };
        var user = this.user;
        var html = "";
        html = '<div class="mine messages">';
        html += '<div class="message last bg-theme">';
        html += msg;
        html += '<span class="checks pull-right icon-clock"></span>';
        html += '</div></div>';
        Ember.$('#scrollChat').append(html);
        var element = document.getElementById('scrollChat');
        element.scrollTop = element.scrollHeight - element.clientHeight;
        Ember.$('#chatInputBox').val("");

        if (event.keyCode === 13) {
          this.socket.chat.emit('sendChat', JSON.stringify(sendChat));
          return;
        } //Click buttom


        this.socket.chat.emit('sendChat', JSON.stringify(sendChat));
      },

      /**
       *
       */
      enterKeyPress: function enterKeyPress() {
        if (event.keyCode === 13) {
          this.send('sendComment');
        }
      },

      /**
       *
       */
      exitConvertation: function exitConvertation() {
        this.socket.chat.emit('switchRoom', null);
        event.stopPropagation();
        this.set('outChat', true);
      },

      /**
       *
       */
      search: function search(e) {
        var keyWord = Ember.$(e.target).val();

        if (keyWord.length > 3) {
          Ember.$('.card.bg_light_notification.margin_ud_10px').hide();
          Ember.$('.card.bg_light_notification.margin_ud_10px:contains(' + keyWord + ')').show();
        } else {
          Ember.$('.card.bg_light_notification.margin_ud_10px').show();
        }
      },

      /**
       *
       */
      close: function close() {
        Ember.$('#chat_box_component').hide();
      }
    }
  });

  _exports.default = _default;
});