define("comunidades-nafin/templates/components/course-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MztFAWaS",
    "block": "{\"symbols\":[\"documentos\",\"&default\"],\"statements\":[[6,\"div\"],[10,\"class\",\"row \"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 text_center\"],[10,\"id\",\"legend\"],[10,\"hidden\",\"\"],[8],[0,\"\\n        \"],[6,\"p\"],[8],[0,\"No hay documentos asociados\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 col-xl\"],[10,\"id\",\"table\"],[8],[0,\"\\n        \"],[6,\"div\"],[10,\"class\",\"row table-responsive \"],[8],[0,\"\\n            \"],[6,\"table\"],[10,\"class\",\"table table-striped\"],[8],[0,\"\\n                \"],[6,\"thead\"],[8],[0,\"\\n                    \"],[6,\"tr\"],[8],[0,\"\\n                        \"],[6,\"th\"],[10,\"scope\",\"col\"],[8],[9],[0,\"\\n                        \"],[6,\"th\"],[10,\"scope\",\"col\"],[8],[0,\"Nombre\"],[9],[0,\"\\n                        \"],[6,\"th\"],[10,\"scope\",\"col\"],[8],[0,\"Fecha de creación\"],[9],[0,\"\\n                    \"],[9],[0,\"\\n                \"],[9],[0,\"\\n                \"],[6,\"tbody\"],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"documentos\"]]],null,{\"statements\":[[0,\"                    \"],[6,\"tr\"],[8],[0,\"\\n                        \"],[6,\"th\"],[10,\"scope\",\"row\"],[8],[2,\" {{fa-icon \\\"heart\\\" size=\\\"lg\\\" prefix=\\\"far\\\"}} \"],[9],[0,\"\\n                        \"],[6,\"td\"],[8],[6,\"a\"],[10,\"href\",\"#\"],[3,\"action\",[[21,0,[]],\"abrirArchivo\",[21,1,[\"ruta\"]]]],[8],[1,[26,\"fa-icon\",[\"file-pdf\"],[[\"size\"],[\"lg\"]]],false],[0,\" \"],[1,[21,1,[\"nombre\"]],false],[9],[9],[0,\"\\n                        \"],[6,\"td\"],[8],[1,[21,1,[\"fecha_creacion\"]],false],[9],[0,\"\\n                    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[13,2]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/components/course-documents.hbs"
    }
  });

  _exports.default = _default;
});