define("comunidades-nafin/templates/nom-035-stps/reportes/administrador/entorno-organizacional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BZ2e6b0B",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Entorno organizacional\\n\"],[1,[20,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "comunidades-nafin/templates/nom-035-stps/reportes/administrador/entorno-organizacional.hbs"
    }
  });

  _exports.default = _default;
});