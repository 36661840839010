define("comunidades-nafin/components/nom-035-stps/reportes/reports/audiencia-resumen-por-trabajador-reporte", ["exports", "comunidades-nafin/components/human-talent-components/h-t-reporte"], function (_exports, _hTReporte) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _hTReporte.default.extend({
    title: 'Estatus de los cuestionarios por trabajador',
    clase: 'nom035stps/reporteadministrador',
    metodo: 'audienciaResumenPorTrabajador',
    reporte: 'nom035stps/export/audienciaReporteResumenPorTrabajador',
    headers: [{
      dataIndex: 'datos_trabajador',
      title: 'Datos de Trabajador'
    }, {
      dataIndex: 'centro_trabajo',
      title: 'Centro de Trabajo'
    }, {
      dataIndex: 'estatus',
      title: 'estatus'
    }]
  });

  _exports.default = _default;
});